import Amplify, {Auth} from "aws-amplify";

export const awsRegion = "eu-west-1"
const poolCi = {
    userPool: "eu-west-1_3oDXzpWA1",
    userPoolWebClient: "2sqt9mp0ih6mcvaj4gatrhs154",
    centralAccountId: "980036580614",
    identityPoolId: "eu-west-1:f19cec4a-edcf-4ca9-b170-3ec9f8e3f6d7"
}

const poolDev = {
    userPool: "eu-west-1_JUxdbHvNM",
    userPoolWebClient: "69gbebk2qii7ume4ic8ed6igdp",
    centralAccountId: "980036580614",
    identityPoolId: "eu-west-1:f19cec4a-edcf-4ca9-b170-3ec9f8e3f6d7"
}

const poolTest = {
    userPool: "eu-west-1_atd7X76RP",
    userPoolWebClient: "5p5quu1nauua3b6qvq6np03bou",
    centralAccountId: "039116223242",
    identityPoolId: "eu-west-1:aed01555-a6ba-445e-8706-7aedf11c9e30"
}

// update local config here
export const localPool = process.env?.REACT_APP_DEV_ENV ? poolDev : poolCi;

const devConfig = {
    Auth: {
        region: awsRegion,
        userPoolId:"eu-west-1_pqzq3aQ8X",
        userPoolWebClientId:"6v6gmfdsmkv85eoomnheavvu0c",
        mandatorySignIn: false,
        storage: localStorage,
        authenticationFlowType: "USER_SRP_AUTH",
    },
};

Amplify.configure(devConfig);

// You can get the current config object
const currentConfig = Auth.configure();
export {currentConfig};

export const centralAccountId = "596568923177"
export const identityPoolId = "eu-west-1:e5d7b4b5-2bc6-4629-8ba3-24f1c32fb574"
export const userPoolId = devConfig.Auth.userPoolId
