import {objectApi} from "../../../application/entities/dataApi";
import {useQuery} from "react-query";
import {BackendError} from "../../../types/backendError";

export const useSegmentCountriesList = (accountId: number) => {
    const accountsApi = new objectApi.accounts();

    const {
        isLoading,
        error,
        data,
        refetch,
        remove
    } = useQuery<any, BackendError>([`segment-countries-list-${accountId}`, `account-${accountId}`, 'accounts'], () => {
        return accountsApi.getSegmentsCountries(+accountId).then((countries) => {
            return countries.data.countries.map((country: any) => {
                return {
                    value: country.code,
                    label: country.name
                }
            });


        })
    }, {
        cacheTime: 30 * 60 * 1000, //30 minutes
        enabled: Boolean(accountId),
    });

    return {
        segmentCountriesListLoading: isLoading,
        segmentCountriesListError: error,
        segmentCountriesListData: data,
        segmentCountriesListRefetch: refetch,
        segmentCountriesListRemove: remove
    }
}