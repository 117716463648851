import Box from "@mui/material/Box";
import {PageId} from "../../application/pages/pages.config";
import {MainFrame} from "../../components/templates/MainFrame";
import React, {useEffect} from "react";
import {Typography} from "@mui/material";
import {useParams} from "react-router";
import {useSegmentBuilderStore} from "./SegmentBuilderStore";
import {SegmentsResults} from "./SegmentsResults";
import {AccountTracker} from "../../components/controls/AccountTracker";
import {SegmentsSideBar} from "./sideBar/SegmentsSideBar";
import {SegmentsSearch} from "./search/SegmentsSearch";
import {useQueryClient} from "react-query";
import {objectApi} from "../../application/entities/dataApi";
import {SegmentSearchBar} from "./search/SegmentSearchBar";
import {DEFAULT_DISTANCE} from "./Segments.utils";
import {AudienceName} from "./AudienceName";

const segmentsApi = new objectApi.externalSegments();


export const SegmentBuilder = () => {
    let {id} = useParams<{ id: string }>() as { id: string };
    const queryClient = useQueryClient()

    const searchDone = useSegmentBuilderStore(st => st.searchDone)
    const isLoading = useSegmentBuilderStore(st => st.isLoading)
    const accountId = useSegmentBuilderStore(st => st.accountId)
    const audienceName = useSegmentBuilderStore(st => st.audienceName)


    const init = useSegmentBuilderStore(st => st.init)


    useEffect(() => {
        if (id === undefined) return
        console.log('call init with id', id, Number(id))
        queryClient.removeQueries('externalSegments')
        useSegmentBuilderStore.setState({isLoading: true})
        init(Number(id))


    }, [id])

    const suggestions = [
        {
            searchTerm: 'Family with young kids',
            emoji: "👫"
        },
        {
            searchTerm: 'People interested in electric cars',
            emoji: "🚘"
        },
        {
            searchTerm: 'Traveler with high income',
            emoji: "🛫"
        },
        {
            searchTerm: 'People who like go camping',
            emoji: "⛺️"
        }
    ]

    const handleSuggestionClick = (term: string) => {
        useSegmentBuilderStore.setState({query: term, distance: DEFAULT_DISTANCE, searchDone: true})
    }

    return <>
        {accountId && <AccountTracker accountId={accountId}/>}
        <MainFrame
            frameId={PageId.segmentBuilder}
            scrollable={true}
            loading={isLoading}
            headerProps={{
                overrideTitleComponent: <AudienceName/>
            }}
        >
            {!isLoading && (
                <Box sx={{
                    height: "100%",
                    overflowY: "auto",
                    flex: 1,
                    padding: "2rem",
                    display: 'flex',
                    gap: '1rem',
                    paddingTop: 0
                }}
                     data-cy="segment-builder">
                    <Box sx={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: '1.5rem',
                    }}>
                        {!searchDone && <Box sx={{width: "600px", maxWidth: "90%", mx: "auto"}}>
                            <Typography variant="h4" fontSize="22px" color="#5c6a7f" align={'center'} mb={4}>
                                What are you looking for?
                            </Typography>


                            <SegmentSearchBar/>

                            <Typography mt={1} mb={1} fontSize="14px" color="#5c6a7f" sx={{fontWeight: "bold"}}>Or try a
                                suggestion..</Typography>


                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gap: '1rem',

                                mx: "auto",

                            }}>
                                {suggestions.map((suggestion, idx) => {
                                    return <Box key={idx} onClick={() => handleSuggestionClick(suggestion.searchTerm)}
                                                sx={{
                                                    border: '1px solid #e4e4e4',
                                                    borderRadius: '8px',
                                                    padding: '0.75rem',
                                                    background: '#fff',
                                                    cursor: 'pointer',
                                                    "&:hover": {
                                                        background: '#f0f2f7'
                                                    }
                                                }}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '1rem'
                                        }}>
                                            <Typography sx={{fontSize: '20px'}}>
                                                {suggestion.emoji}
                                            </Typography>
                                            <Typography color="#5c6a7f"
                                                        sx={{fontSize: '14px', fontWeight: 'bold', lineHeight: '1.1'}}>
                                                {suggestion.searchTerm}
                                            </Typography>
                                        </Box>
                                    </Box>
                                })}

                            </Box>

                        </Box>
                        }

                        {searchDone && <>
                            <SegmentsSearch/>

                            <SegmentsResults/>

                        </>}

                    </Box>
                    <SegmentsSideBar/>

                </Box>

            )}
        </MainFrame>
    </>
}