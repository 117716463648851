import {IAudiences} from "application/entities/dataTypes/audiences";
import {DspCampaignInfo, DspItems} from "application/entities/dataTypes/dspCampaignInfo";
import {IExternalSegmentActivation} from "../entities/dataTypes/audienceActivationRecap";

export const ConvertEnabledAudienceCheckoutParamsToDspItems = (audience: IAudiences): Array<DspItems & {
    externalSegmentActivations?: IExternalSegmentActivation[]
}> => {
    if (!audience?.dspCampaignInfo) return [];
    const dsps = Array.from(audience?.dspCampaignInfo);
    let dspList: DspItems[] = [];
    dsps.forEach((dsp: DspCampaignInfo) => {
        dsp.dspItems.forEach((item: DspItems) => {
            dspList.push(item);
        });
    });

    const audienceRecapWithCheckoutParams = audience.audienceActivationRecap
        ?.map((recap) => {
            if (recap.checkoutParameters && recap.checkoutParameters !== "{}") {
                const formattedCheckoutParams = JSON.parse(recap.checkoutParameters);
                const checkoutParams = {
                    dspCode: recap.dspCode,
                    dspName: recap.dspName,
                    checkoutParameters: formattedCheckoutParams,
                    externalSegmentActivations: recap.externalSegmentActivations

                };
                return checkoutParams;
            }
            return null;
        })
        .filter((el) => el !== null);

    const activatedDspCode = audience?.audienceActivationRecap?.map((recap) => recap.dspCode);

    const activatedDspList: Array<DspItems & { externalSegmentActivations?: IExternalSegmentActivation[] }> = dspList
        .filter((dsp: DspItems) => activatedDspCode?.includes(dsp.code))
        .filter((dsp: DspItems) => dsp.dspCheckoutParameterGroups.length > 0);

    activatedDspList?.map((dsp) => {
        const activeParams = audienceRecapWithCheckoutParams?.find((recap) => recap?.dspCode === dsp.code)?.checkoutParameters;
        const externalSegmentActivations = audienceRecapWithCheckoutParams?.find((recap) => recap?.dspCode === dsp.code)?.externalSegmentActivations;
        dsp.externalSegmentActivations = externalSegmentActivations;
        dsp.dspName = audienceRecapWithCheckoutParams?.find((recap) => recap?.dspCode === dsp.code)?.dspName;
        if (activeParams) {
            dsp.dspCheckoutParameterGroups?.map((group) => {
                return group.dspCheckoutParameters?.map((param) => {
                    param.selected = activeParams[param.code];
                    return param;
                });
            });
        }
        return dsp;
    });

    return activatedDspList as Array<DspItems & { externalSegmentActivations?: IExternalSegmentActivation[] }>;
};
