import {useSegmentBuilderStore} from "../SegmentBuilderStore";
import {useShallow} from "zustand/react/shallow";
import {Typography} from "@mui/material";
import React from "react";
import {getCPMFromSegmentsList, getCPMToDisplay} from "../Segments.utils";

export const SegmentCpm = () => {
    const selectedSegments = useSegmentBuilderStore(useShallow((state) => state.selectedSegments))
    const getCpm = useSegmentBuilderStore(useShallow((state) => state.getCpm))
    const currency = useSegmentBuilderStore(useShallow((state) => state.currency))

    const {cpmMin, cpmMax} = getCPMFromSegmentsList(selectedSegments)

    const displayedCpm = (!cpmMin && !cpmMax) ? 'Not set' : getCPMToDisplay(getCpm(cpmMin), getCpm(cpmMax), currency)

    return <>
        <Typography variant="h4" fontSize="16px">CPM: {displayedCpm}</Typography>
    </>
}