import {Box, Button, Divider, Skeleton, styled, Theme, Typography} from "@mui/material";
import {actions} from "application/actions/actions";
import {objectApi} from "application/entities/dataApi";
import {IAudiences} from "application/entities/dataTypes/audiences";
import {DspItems} from "application/entities/dataTypes/dspCampaignInfo";
import {pageUtils} from "application/pages/pages.utils";
import {AUTHUser} from "application/utils/AuthUser";
import {ConvertEnabledAudienceCheckoutParamsToDspItems} from "application/utils/audience.utils";
import {getDspIcon} from "application/utils/dsp.utils";
import {ChannelSummary} from "components/audiences/channelSummary";
import {AudienceDetails} from "components/audiences/details";
import {ActionButtons} from "components/controls/ActionButtons";
import {useAppToast} from "components/live/AppToast";
import {useOpenDialogWindow} from "components/live/DialogWindow";
import {MainFrame} from "components/templates/MainFrame";
import {AppDrawer} from "components/ui/AppDrawer";
import {GridMiddle} from "components/ui/AppElements";
import {AppIcon} from "components/ui/AppIcon";
import {useAccountTracker} from "pages/Agencies/useAccountTracker";
import {useAudienceBuilderStore} from "pages/AudienceBuilder/Store/AudienceBuilderStore";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {IOpenDialogAction} from "redux/slices/dialog.slices";
import {AudienceViewAttributes} from "../../../components/audiences/audienceViewAttributes/AudienceViewAttributes";
import {AudienceCard} from "../Card";
import {FloatingMenuHandle} from "../Card/FloatingMenuHandle";
import {ProviderStatus} from "../Card/ProviderStatus";
import {CopyToBrandPopup} from "../CopyToBrandPopup";
import {DspCheckoutParametersBox} from "../Enablement/components/SummaryStep/DspCheckoutParametersBox";
import {SegmentsList} from "../../../components/audiences/segmentsList/segmentsList";
import {getSegmentListFromAudienceFeature} from "../../SegmentBuilder/Segments.utils";

export const AudienceView = () => {
    const {t} = useTranslation();
    const {audienceId} = useParams<{ audienceId: string }>() as { audienceId: string };
    const audienceApi = new objectApi.audiences();
    const [audience, setAudience] = useState<IAudiences>({} as IAudiences);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState(false);
    const [visibleDrawer, setVisibleDrawer] = React.useState<boolean>(false);
    const [menuMode, setMenuMode] = React.useState<"transparency" | "channels" | "enablement" | undefined>(undefined);

    const isSegmentAudience = audience?.audienceType?.code === "SEGMENTS"

    let provStatus = visibleDrawer ? {right: "340px", top: "0.5rem"} : {right: 0, top: 0};
    const drawerTitle =
        menuMode === "channels"
            ? t("audience.cardTips.channelTipTitle")
            : menuMode === "enablement"
                ? t("audience.cardTips.enablementTipTitle")
                : t("audience.cardTips.transparencyTipTitle");
    const drawerTip =
        menuMode === "channels"
            ? t("audience.cardTips.channelTip")
            : menuMode === "enablement"
                ? t("audience.cardTips.enablementTip")
                : t("audience.cardTips.transparencyTip");

    const audienceActions = useAudienceBuilderStore((st) => st.actions);

    useAccountTracker(audience?.account?.id);

    const defaultMode = "transparency";

    const dialogWindow = useOpenDialogWindow();

    //temporary fake featureflag
    const editParamsFlag = false;

    React.useEffect(() => {
        dialogWindow.close("AudiencePreviewPopup");
        setLoading(true);
        audienceApi
            .openAndRefreshAudience(Number(audienceId))
            .then((res) => {
                if (!["ARCHIVED", "ACTIVATED"].includes(res.data.audienceStatus)) {
                    setError(true);
                }
                if (res) {
                    setAudience(res.data);
                    if (res.data?.audienceType?.code !== "SEGMENTS") {
                        console.log('init audience')
                        Promise.all([audienceActions.init(Number(audienceId))])
                            .catch((err) => {
                                console.log(err);
                                setError(true);
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    } else {
                        setLoading(false);
                    }
                }
            })
            .catch((e) => {
                setLoading(false);
                setError(true);
                console.log(e);
            });
        return () => {
            if (!isSegmentAudience) {
                audienceActions.reset();
            }
        };
    }, []);

    function setDrawerMode(mode: "transparency" | "channels" | "enablement") {
        if (mode === menuMode) {
            /*if (visibleDrawer) setMenuMode(undefined);
                 setVisibleDrawer(!visibleDrawer);*/
        } else {
            setMenuMode(mode);
            setVisibleDrawer(true);
        }
    }

    function handleToggleDrawer(fact: any) {
        if (fact && !menuMode) setMenuMode(defaultMode);
        setVisibleDrawer(fact);
    }


    const activatedDspList = ConvertEnabledAudienceCheckoutParamsToDspItems(audience);
    return (
        <MainFrame
            breadCrumbProps={{crumbTitle: error ? "Audience" : loading ? "Audience" : `Audience - ${audience?.name}`}}
            headerProps={{
                headerTitle: loading ? (
                    <Skeleton variant="rectangular" width={"100%"} height={24} style={{borderRadius: "10px"}}/>
                ) : (
                    <Typography variant={"h4"}>{error ? "Error" : `Audience details : ${audience?.name}`}</Typography>
                ),
            }}
            headerContent={<AudienceViewHeader audience={audience}/>}
            frameId={"audience-view"}
            loading={loading}
            checkPageAccess={true}
            grantWith={{accountId: AUTHUser.accountId}}
        >
            {error ? (
                <ContainerFull style={{marginTop: "0"}}>
                    <Typography variant={"h4"} style={{paddingBottom: "10px", textAlign: "center"}}>
                        Error loading audience
                    </Typography>
                </ContainerFull>
            ) : <>
                {isSegmentAudience && <>
                    <Container>
                        <div>
                            <AudienceDetails audience={audience} loading={loading}/>
                        </div>

                        <div>
                            <Typography variant={"h5"} style={{paddingBottom: "10px"}}>
                                Segments Selected
                            </Typography>
                            <Divider style={{marginBottom: "30px"}}/>
                            <SegmentsList segments={getSegmentListFromAudienceFeature(audience) ?? []}
                                          accountId={audience.account.id}
                                          currency={audience?.displayCurrency ?? "USD"}/>
                        </div>
                    </Container>

                    <ContainerFull>
                        <Typography variant={"h5"} mb={3}>
                            Channels Activation
                        </Typography>
                        <Box display="grid" gridTemplateColumns="1fr" gap={3}>
                            {activatedDspList.map((dsp, index: number) => {
                                return (
                                    <Box key={dsp.code} sx={{
                                        background: "#fff",
                                        padding: "15px",
                                        borderRadius: "8px",
                                        border: "1px solid #ececec"
                                    }}>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 2,
                                            justifyContent: "space-between"
                                        }}>
                                            <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
                                                <AppIcon fontSize={"middle"} icon={getDspIcon(dsp.code)}/>
                                                <Box>
                                                    <Typography sx={{fontWeight: "bold", fontSize: "14px"}}
                                                                data-cy={"dspName"}>
                                                        {dsp?.dspName}
                                                    </Typography>
                                                    <Typography className="color-tech-grey"
                                                                data-cy={"seatName"}>
                                                        {dsp.seatName} - {dsp.name}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {audience.audienceStatus === "ACTIVATED" && editParamsFlag && (
                                                <Button size="small" variant="contained">
                                                    Edit
                                                </Button>
                                            )}
                                        </Box>

                                        <Box mt={2}>
                                            <Typography variant={"h4"} fontSize="15px" pb={0} mb={2}>
                                                Segments Activated
                                            </Typography>
                                            <SegmentsList segments={dsp?.externalSegmentActivations ?? []}
                                                          isExternalSegmentActivation={true}
                                                          accountId={audience.account.id}
                                                          currency={audience?.displayCurrency ?? "USD"}/>
                                        </Box>

                                        <Box>
                                            <Typography variant={"h4"} fontSize="15px" pb={0} mb={0}>
                                                Channels Activation Parameters
                                            </Typography>
                                            <DspCheckoutParametersBox paramGroups={dsp.dspCheckoutParameterGroups}
                                                                      isCollapseDisabled={true}></DspCheckoutParametersBox>
                                        </Box>

                                    </Box>
                                );
                            })}
                        </Box>
                    </ContainerFull>
                </>}

                {!isSegmentAudience && <>
                    <Box style={{height: "100%", overflowY: "auto", overflowX: "auto"}}>
                        <Container>
                            <div>
                                <AudienceDetails audience={audience} loading={loading}/>
                            </div>

                            <div>
                                {loading ? (
                                    <Skeleton variant="rectangular" width={"100%"} height={400}
                                              style={{borderRadius: "10px"}}/>
                                ) : <AudienceViewAttributes loading={loading}/>}
                            </div>
                        </Container>

                        <ContainerFull>
                            {loading ? (
                                <Skeleton variant="rectangular" width={"100%"} height={400}
                                          style={{borderRadius: "10px"}}/>
                            ) : <>
                                {!isSegmentAudience && <ChannelSummary audience={audience} loading={loading}/>}

                            </>}
                        </ContainerFull>

                        {activatedDspList.length > 0 && (
                            <ContainerFull style={{marginTop: 0}}>
                                <Typography variant={"h5"} mb={3}>
                                    Channels Activation Parameters
                                </Typography>
                                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={3}>
                                    {activatedDspList.map((dsp: DspItems, index: number) => {
                                        return (
                                            <Box key={dsp.code} sx={{
                                                background: "#fff",
                                                padding: "15px",
                                                borderRadius: "8px",
                                                border: "1px solid #ececec"
                                            }}>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 2,
                                                    justifyContent: "space-between"
                                                }}>
                                                    <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
                                                        <AppIcon fontSize={"middle"} icon={getDspIcon(dsp.code)}/>
                                                        <Box>
                                                            <Typography sx={{fontWeight: "bold", fontSize: "14px"}}
                                                                        data-cy={"dspName"}>
                                                                {dsp?.dspName}
                                                            </Typography>
                                                            <Typography className="color-tech-grey"
                                                                        data-cy={"seatName"}>
                                                                {dsp.seatName} - {dsp.name}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    {audience.audienceStatus === "ACTIVATED" && editParamsFlag && (
                                                        <Button size="small" variant="contained">
                                                            Edit
                                                        </Button>
                                                    )}
                                                </Box>

                                                <DspCheckoutParametersBox paramGroups={dsp.dspCheckoutParameterGroups}
                                                                          isCollapseDisabled={true}></DspCheckoutParametersBox>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </ContainerFull>
                        )}
                    </Box>
                </>}
            </>}
            <FloatingMenuHandle position={provStatus}>
                <div data-icon-button-zone className={"pad flex-v gap-tiny"}>
                    <button style={{width: "24px"}} data-cy={"btnActionToggleAudienceDrawer"}
                            onClick={() => handleToggleDrawer(!visibleDrawer)}>
                        <AppIcon
                            themeColor={!visibleDrawer ? "primary" : "secondary"}
                            fontSize={"small"}
                            icon={"ChevronDownIcon"}
                            rotate={visibleDrawer ? -90 : 90}
                        />
                    </button>
                    <button data-cy={"btnActionOpenTransparencyCard"} onClick={() => setDrawerMode("transparency")}>
                        <AppIcon themeColor={menuMode === "transparency" ? "primary" : "secondary"} fontSize={"small"}
                                 icon={"FileSearch"}/>
                    </button>
                    {["DRAFT"].includes(audience.audienceStatus) && (
                        <button style={{width: "24px"}} data-cy={"btnActionOpenChannels"}
                                onClick={() => setDrawerMode("channels")}>
                            <AppIcon themeColor={menuMode === "channels" ? "primary" : "secondary"}
                                     icon={"ChannelsNav"}/>
                        </button>
                    )}
                    {!["DRAFT"].includes(audience.audienceStatus) && (
                        <button style={{width: "24px"}} data-cy={"btnActionOpenEnablement"}
                                onClick={() => setDrawerMode("enablement")}>
                            <AppIcon themeColor={menuMode === "enablement" ? "primary" : "secondary"} fontSize={"small"}
                                     icon={"Channels"}/>
                        </button>
                    )}
                </div>
            </FloatingMenuHandle>

            <AppDrawer
                title={drawerTitle}
                helperText={drawerTip}
                helperTitle={drawerTitle}
                open={visibleDrawer}
                hideHandle={true}
                actionButtonsVars={{...audience, audienceId: audience?.id}} // add audienceId
                onClose={() => {
                    handleToggleDrawer(false);
                }}
            >
                {menuMode === "channels" && <ProviderStatus audienceData={audience} position={provStatus}
                                                            isSegmentAudience={audience?.audienceType?.code === "SEGMENTS"}/>}
                {menuMode !== "channels" && <AudienceCard menuMode={menuMode} audience={audience}/>}
            </AppDrawer>
        </MainFrame>
    );
};

const AudienceViewHeader = (props: { audience: IAudiences }) => {
    const audience = props.audience;
    //hooks
    const {t} = useTranslation();
    const history = useNavigate();
    const appToast = useAppToast();
    const dialogWindow = useOpenDialogWindow();
    const audiencesApi = new objectApi.audiences();
    const dialogDuplicate: IOpenDialogAction = {
        dialogId: "audienceDuplicate",
        buttonActions: {acceptButton: true, cancelButton: true},
        title: t("audience.duplicateAudience"),
        description: t("audience.duplicateAudienceDescription"),
        icon: "Duplicate",
        onAccept: (args: any, dta: { id: number; data: Partial<IAudiences> }) => {
            audiencesApi
                .duplicateAudience(dta.data)
                .then((res: any) => {
                    if (res && res.id) {
                        history(pageUtils.getPagePathById("audience-builder-update", {id: res.id}));
                    }
                })
                .catch(() => {
                    appToast.error({
                        message: "Boom !",
                    });
                });
        },
    };
    const actionButtons = {...actions.audience};
    delete actionButtons.edit;
    delete actionButtons.activate;
    delete actionButtons.archive;
    delete actionButtons.view;
    delete actionButtons.trash;

    actionButtons.copy.action = (audienceData: IAudiences) => {
        dialogWindow.open({...dialogDuplicate, dialogArgs: {id: audienceData.id, data: audienceData}});
    };
    actionButtons.copyToBrand.action = (audienceData: IAudiences) => {
        dialogWindow.open({
            dialogId: "copyToBrand",
            component: CopyToBrandPopup,
            componentProps: {
                audience: audienceData,
            },
        });
    };
    const headerContent = (
        <div className="history-zone" style={{display: "block"}}>
            <GridMiddle style={{justifyContent: "end"}}>
                <Box sx={{p: 1}}>
                    <ActionButtons buttonSize={"small"} actions={actionButtons}
                                   vars={{...audience, audienceId: audience?.id}}/>
                </Box>
            </GridMiddle>
        </div>
    );

    return headerContent;
};

const Container = styled("div")((props: { theme: Theme }) => ({
    height: "auto",
    width: "100%",
    padding: "1.5rem",
    display: "grid",
    gridTemplateColumns: "380px 1fr",
    gap: "3rem",
}));

const ContainerFull = styled(Box)((props: { theme: Theme }) => ({
    width: "100%",
    marginTop: "2rem",
    padding: "1.5rem",
}));
