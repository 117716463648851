export const apiConfig = {
    apiUrl: `https://prod-api.app.evorra.com`,
    apiUrlCI: `https://prod-api.app.evorra.com`,
    apiUrlTest: `https://prod-api.app.evorra.com`,
}

export const weaviateConfig = {
    ciUrl: `https://prod-v2.app.evorra.com`,
    prodUrl: `https://prod-v2.app.evorra.com`,
    testUrl: `https://prod-v2.app.evorra.com`,
    localUrl: 'http://localhost:3001'
}

export const devApi = process.env?.REACT_APP_DEV_ENV ? apiConfig.apiUrl : apiConfig.apiUrlCI;

export const segmentApiUrl = process.env?.REACT_APP_DEV_ENV ? weaviateConfig.prodUrl : weaviateConfig.ciUrl