import {MenuItem} from "@mui/material";
import {CountryFlag} from "../../../components/ui/CountryFlag/CountryFlag";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import React from "react";
import {useSegmentBuilderStore} from "../SegmentBuilderStore";


export const SegmentCountryFilter = () => {

    const countries = useSegmentBuilderStore(st => st.countriesList)
    const countryCode = useSegmentBuilderStore(st => st.countryCode)

    const handleChange = (event: SelectChangeEvent<typeof countryCode>) => {
        const {
            target: {value},
        } = event;
        if (Array.isArray(value)) {

            useSegmentBuilderStore.setState({countryCode: value.includes('') ? [] : value, page: 0})
        }
    }
    return <>
        <Select value={countryCode}
                renderValue={(value) => {
                    if (value?.length === 0) return <>All Countries</>
                    return <>{value.length} selected</>
                }}

                displayEmpty
                multiple={true}
                disabled={countries.length === 1}
                onChange={handleChange}
                sx={{
                    width: '200px', height: '40px', "& .MuiInputBase-input": {
                        display: 'flex', alignItems: 'center',
                        justifyContent: 'flex-start !important',
                        gap: '0.25rem'
                    }
                }}>
            {countries.map(({value, label}) => <MenuItem key={label} value={value} sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.25rem'
            }}>
                {value.length ? <CountryFlag countryCode={value}/> : <></>} {label}
            </MenuItem>)}
        </Select>
    </>
}