import {IAudiences} from "../../../../application/entities/dataTypes/audiences";
import moment from "moment";
import {Elem, H6} from "../../../../components/ui/AppElements";
import {TagListMini} from "../../../../components/organisms/TagListMini";
import {AppIcon} from "../../../../components/ui/AppIcon";
import {Box, Button, Divider, Typography} from "@mui/material";
import {IActionButtons} from "../../../../components/controls/ActionButtons";
import {profilesFormatter} from "../../../../_configuration/formaters";
import {ColorPicker} from "../../../../components/form/ColorPicker";
import {ActionMenu} from "../../../../components/controls/ActionMenu";
import {Theme} from "@mui/material/styles";
import {styled} from "@mui/styles";
import {AUTHUser} from "application/utils/AuthUser";
import {useOpenDialogWindow} from "components/live/DialogWindow";
import React from "react";
import {IOpenDialogAction} from "redux/slices/dialog.slices";
import {AudiencePreviewPopup} from "./audiencePreviewPopup";

interface IAudienceLibraryThumbProps {
    audience: IAudiences;
    selectedThumb: boolean;
    onClick: (id: any) => void;
    onDblClick: (id: any, audienceStatus: string, isFormMe?: boolean) => void;
    onDataChange: (args: Record<string, any>) => void;
    onDelete?: (arg: any, org: any) => void;
    actionMenu?: IActionButtons;
}


export const AudienceLibraryThumb = (props: IAudienceLibraryThumbProps) => {
    const {
        audience,
        selectedThumb,
        onClick,
        onDblClick,
        onDataChange,
        onDelete,
        actionMenu,
    } = props;

    const isSegmentAudience = audience.audienceType?.code === "SEGMENTS"

    const windowDialog = useOpenDialogWindow();
    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

    const tagList2 = toTaglList(audience.tags, "name");
    const newDate = moment(new Date(audience.creationTime)).format("D-MMM-YYYY h:mm A");
    const modificationTime = moment(audience.modificationTime).format(
        "D-MMM-YYYY h:mm A"
    );
    const lastAudienceSize =
        audience.lastAudienceSize === -1 || audience.lastAudienceSize === undefined
            ? "< Minimum"
            : `${profilesFormatter.format(audience.lastAudienceSize)}`;

    function toTaglList(list: any[], field: string) {
        return (list ?? []).map((listItem) => {
            return {id: listItem.id, text: listItem[field]};
        });
    }

    function handleColorChange(args: { color: string; id?: number }) {
        // update color in place
        // setColor(args.color);
        if (onDataChange) {
            onDataChange({color: args.color, id: args.id});
        }
    }

    const previewDialog: IOpenDialogAction = {
        dialogId: 'AudiencePreviewPopup',
        component: AudiencePreviewPopup,
        componentProps: {
            audienceId: audience.id,
            previewMode: true,
            onDelete: onDelete
        },
        dialogProps: {
            className: 'previewAudiencePopup'
        }
    };

    const handleOpenPreviewDialogWindow = () => {
        windowDialog.open(previewDialog)
            .then();
    };

    return (
        <ThumbContainer
            key={`${audience.name}_${audience.id}`}
            data-cy={"AudienceBuilderThumb"}
            data-cy-test-id={audience.id}
            data-cy-test-name={audience.name}

            onDoubleClick={() => {
                onDblClick(audience.id, audience.audienceStatus, (audience.audienceStatus === 'PENDING_APPROVAL' && audience.approverAsked.id === AUTHUser.userId));
            }}
            active={selectedThumb}>
            <Box className={"flex-v"} sx={{height: "100%"}}>
                <div
                    className="flex-h flex-align-middle"
                    style={{marginBottom: "0.5rem", width: "100%", gap: "0.5rem"}}>
                    <ColorPicker
                        id={audience?.id}
                        onChange={handleColorChange}
                        trigger={<ThumbBadge backgroundColor={audience.color}/>}
                    />
                    <Box style={{overflow: "hidden", minWidth: 0, flex: "1"}}>
                        <ThumbTitle>
                            <Typography
                                data-cy={"audience-builder-card-title"}
                                noWrap={true}
                                variant={"h6"}>
                                {audience.name}
                            </Typography>
                        </ThumbTitle>
                    </Box>
                    <Box>
                        <ActionMenu
                            actions={actionMenu as IActionButtons}
                            vars={{...audience, audienceId: audience.id}}
                        />
                    </Box>
                </div>
                {Boolean(audience.tags?.length) && (
                    <Box sx={{mb: 1}}>
                        <TagListMini list={tagList2}/>
                    </Box>
                )}
                <div className="flex-main "/>
                <Box className={"flex-h flex-align-middle"}>
                    <Box gap={1} className={"flex-v flex-main"}>
                        <Box gap={1} className={"flex-main flex-h flex-align-middle"}>
                            {!isSegmentAudience && <>
                                <Box>
                                    <AppIcon
                                        fontSize={"small"}
                                        icon={"Audiences"}
                                        color={"#7587A3"}
                                    />
                                </Box>
                                <H6>{lastAudienceSize}</H6>
                            </>}

                            {isSegmentAudience && <>
                                <Typography sx={{fontWeight: "bold"}}>Marketplace Audience</Typography>
                            </>}
                            <AppIcon
                                onClick={() => {
                                    onClick(audience.id);
                                }}
                                dataCy={"audienceTransparencyCardIcon"}
                                display={"inline"}
                                fontSize={"small"}
                                icon={"FileSearch"}
                                title={"Open Transparency card"}
                            />
                        </Box>
                        {/*<Box className={'flex-main'}>
           <AppIcon data-cy={'audienceTransparencyCardIcon'} display={'inline'} fontSize={'small'} icon={'FileSearch'}/>
           </Box>*/}
                    </Box>
                    <Box gap={1} className={"flex-v"}>
                        <Box className={"flex-h"} gap={"4px"} style={{fontSize: "10px"}}>
                            <div className={"flex-main color-tech-grey"}>Audience ID</div>
                            <div>{audience.id}</div>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{py: 0.5}}>
                    <Divider/>
                </Box>
                <Box sx={{display: "flex", flexDirection: "column"}} gap={1}>
                    <div>
                        {audience.audienceStatus === "PENDING_APPROVAL" ? <>
                            <div className={"color-tech-grey"}>Requested on</div>
                            <div>{moment(new Date(audience.requestApprovalTime)).format("D-MMM-YYYY h:mm A")}</div>
                        </> : audience.audienceStatus === "APPROVED" ? <>
                                <div className={"color-tech-grey"}>Approved on</div>
                                <div>{moment(new Date(audience.approvalTime)).format("D-MMM-YYYY h:mm A")}</div>
                            </> :
                            <>
                                <div style={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <div>
                                        <div className={"color-tech-grey"}>Last Modified</div>
                                        <div>{modificationTime}</div>
                                    </div>
                                    {!isSegmentAudience && <div>
                                        <Button onClick={handleOpenPreviewDialogWindow} size="small"
                                                variant="contained">Preview</Button>
                                    </div>}

                                </div>
                            </>
                        }
                    </div>
                    {!["PENDING_APPROVAL", "APPROVED"].includes(audience.audienceStatus) && <div>
                        <div className={"color-tech-grey"}>Creator</div>
                        <div>
                            {`${audience.author?.firstName} ${audience.author?.lastName}`}
                        </div>
                    </div>
                    }

                    {audience.audienceStatus === "PENDING_APPROVAL" &&
                        <div style={{display: 'flex', gap: '0.5rem', justifyContent: 'space-between'}}>
                            <div>
                                <div className={"color-tech-grey"}>Requested by</div>
                                <div>
                                    {`${audience.requester.firstName} ${audience.requester.lastName}`}{" "}
                                </div>
                            </div>

                            <div style={{textAlign: 'right'}}>
                                <div className={"color-tech-grey"}>Approver asked</div>
                                <div>
                                    {audience.approverAsked.id === AUTHUser.userId ? 'You' : `${audience.approverAsked.firstName} ${audience.approverAsked.lastName}`}
                                </div>
                            </div>
                        </div>
                    }

                    {audience.audienceStatus === "APPROVED" &&
                        <div style={{display: 'flex', gap: '0.5rem', justifyContent: 'space-between'}}>
                            <div>
                                <div className={"color-tech-grey"}>Requested by</div>
                                <div>
                                    {`${audience.requester.firstName} ${audience.requester.lastName}`}{" "}
                                </div>
                            </div>

                            <div style={{textAlign: 'right'}}>
                                <div className={"color-tech-grey"}>Approved by</div>
                                <div>
                                    {audience.approver.id === AUTHUser.userId ? 'You' : `${audience.approver.firstName} ${audience.approver.lastName}`}
                                </div>
                            </div>
                        </div>
                    }


                </Box>
                <Box sx={{py: 0.5}}>
                    <Divider/>
                </Box>
                <div className={"flex-h flex-align-middle"}>
                    <div className={"flex-main"}></div>
                    <div>
                        <div style={{textTransform: "capitalize", color: "#7587A3"}}>
                            <StatusTag>
                                {audience.audienceStatus
                                    .toLowerCase()
                                    .replace("activated", "Enabled").replace("pending_approval", "Pending Approval")}
                            </StatusTag>
                        </div>
                    </div>
                </div>
            </Box>
        </ThumbContainer>
    );
};


const StatusTag = styled(Elem)({
    backgroundColor: "#F0F2F7",
    display: "inline-block",
    borderRadius: "80px",
    // margin         : '0.25rem',
    // marginLeft     : 0,
    position: "relative",
    //maxWidth     : '90px',
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    minWidth: "43px",
    color: "#7587A3",
    textTransform: "capitalize",
    textAlign: "center",
    padding: "0 0.5rem",
});

const ThumbContainer = styled(Box)((props: { theme: Theme } & any) => ({
    padding: "1rem 1rem 0 1rem",
    backgroundColor: props.theme.palette.background.default,
    overflow: "hidden",
    position: "relative",
    minWidth: "290px",
    width: "290px",
    maxWidth: "290px",
    boxShadow: props.active
        ? "0px 0px 12px rgba(196, 211, 241, 0.2)"
        : props.theme.skin.boxShadow,
    border: "1px solid transparent",
    borderColor: props.active ? "#00B5E2" : "transparent",
    "&:hover": {
        border: props.active ? "" : "1px solid #ccc",
        boxShadow: props.theme.skin.boxShadow,
    },
    borderRadius: "12px",
    contentVisibility: "auto",
}));

const ThumbTitle = styled(Box)({
    alignItems: "center",
    alignContent: "center",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    minWidth: 0,
});

const ThumbBadge = styled(Elem)<any>((props) => ({
    position: "relative",
    overflow: "hidden",
    height: "1rem",
    width: "0.38rem",
    backgroundColor: props.backgroundColor || "chartreuse",
    borderRadius: "100px",
}));
