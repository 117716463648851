import {TIcon} from '../../../assets/icons';
import {Box, Menu, MenuItem} from '@mui/material';
import {AppIcon} from '../../ui/AppIcon';
import {Elem} from '../../ui/AppElements';
import React from 'react';
import {IActionButtons} from '../ActionButtons';
import {pageUtils} from '../../../application/pages/pages.utils';
import {ActionGranter} from '../../features/ActionGranter';
import {useNavigate} from 'react-router-dom';

export interface IAppThumbOption {
    icon: TIcon,
    action: (id: number, args?: any) => void, // (event: React.MouseEvent<HTMLElement>) => {},
    text?: string | any,
}

// IActionButtons
interface ActionMenuProps {
    actions: IActionButtons;
    vars: Record<string, any>;
}

/* duplicate of ActionButtons !!! */
export const ActionMenu = (props: ActionMenuProps) => {

    const {actions, vars} = props;
    const history = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const getActions = (key: string, args?: any) => {

        const act = actions[key];
        if (!act) return;
        if (act.pageId) {
            const pageId = typeof act.pageId === "function" ? (act.pageId(vars) as string) : act.pageId
            history(pageUtils.getPagePathById(pageId, args));
            return;
        }
        if (act.action) {
            act.action(args);
            return;
        }
    };


    return <Box data-cy={'actionMenu'}><AppIcon onClick={handleClickMenu} display={'inline'} fontSize={'small'}
                                                icon={'VerticalEllipsis'}/>
        <Menu open={open} onClose={handleCloseMenu} anchorEl={anchorEl}>
            {Object.keys(actions).map((action: string, index) => {
                // @ts-ignore
                const act = actions[action];

                let accesses;
                let objMatch;

                if (act.accesses) accesses = act.accesses;
                if (act.objMatch) objMatch = act.objMatch;

                if (act.pageId) {
                    const pageId = typeof act.pageId === "function" ? (act.pageId(vars) as string) : act.pageId
                    const page = pageUtils.getPageById(pageId);
                    // accesses is overridden by pageId
                    if (page._accesses) accesses = page._accesses;
                }

                // use the act.condition to apply dedicated rule
                if (act.condition && !act.condition(vars)) return null;

                const icon = typeof act.icon === 'function' ? act.icon(vars) as TIcon : act.icon
                const title = typeof act.title === 'function' ? act.title(vars) as string : act.title

                return <ActionGranter key={index} objMatch={objMatch} accesses={accesses}>
                    <MenuItem data-cy={actions?.code} key={`actionKey${action}`} onClick={() => {
                        getActions(action, vars);
                        handleCloseMenu();
                    }}>
                        {act.icon && <AppIcon color={'#000000'} display={'inline'} fontSize={'small'}
                                              icon={icon ?? 'HelpIcon'}/>}
                        <Elem padding={[0, 0.5]}>
                            <span data-cy={'MenuItem'} data-cy-action={act.icon}>{title}</span>
                        </Elem>
                    </MenuItem>
                </ActionGranter>;
            })}
        </Menu></Box>;
};