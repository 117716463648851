import {useSegmentBuilderStore} from "../SegmentBuilderStore";
import {Box, Collapse, Typography} from "@mui/material";
import {providerIconList} from "../../../assets/providerIcons";
import {AppIcon} from "../../../components/ui/AppIcon";
import {getDspIcon} from "../../../application/utils/dsp.utils";
import React from "react";
import {
    getCPMFromSegmentsList,
    getCPMToDisplay,
    getIdName,
    getMaxFromIdsCountList,
    getTotalIdsForSegmentsList,
    IdCount
} from "../Segments.utils";
import {profilesFormatter} from "../../../_configuration/formaters";
import {useShallow} from "zustand/react/shallow";

export const SegmentsDspInfos = () => {

    const dspCampaignInfo = useSegmentBuilderStore((state) => state.dspCampaignInfo)

    const channelsSelected = useSegmentBuilderStore((state) => state.channelsSelected)
    const getCpm = useSegmentBuilderStore(useShallow((state) => state.getCpm))

    const currency = useSegmentBuilderStore(useShallow((state) => state.currency))


    const isGroupActive = (groupCode: string) => {
        if (channelsSelected === undefined || !channelsSelected?.length) return true
        const res = channelsSelected.some(channel => channel.includes(groupCode))

        return res
    }


    return <Box>
        {dspCampaignInfo?.map((dsp) => {

            if (!isGroupActive(dsp.groupCode)) {
                return null
            }

            return <Box key={dsp.groupCode}
                        sx={{mb: 2}}>
                <Box sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                    mb: 1,
                    background: '#E4EAF0',
                    padding: '0.5rem'
                }}>

                    <AppIcon className="dsp-logo" fontSize={"smallbis"} icon={providerIconList[dsp.groupCode]}/>
                    <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: '14px'}}>
                        {dsp.groupName}
                    </Typography>


                </Box>
                {dsp.dspItems.map((item) => {
                    const {cpmMin, cpmMax} = getCPMFromSegmentsList(item.externalSegments)

                    const displayedCpm = (!cpmMin && !cpmMax) ? 'Not set' : getCPMToDisplay(getCpm(cpmMin), getCpm(cpmMax), currency)

                    const idCount = getTotalIdsForSegmentsList(item.externalSegments)
                    return <Box key={item.code} sx={{ml: 3, mb: 1}}>
                        <Box sx={{display: "flex", gap: "0.5rem", alignItems: "center", mb: 0.5}}>
                            <AppIcon className="dsp-logo" fontSize={"small"} icon={getDspIcon(item.code)}/>
                            <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                                {item.name}
                            </Typography>
                        </Box>
                        <IdsDetails idCount={idCount}/>

                        <Box sx={{
                            mx: 3, mt: 0.5, cursor: 'pointer', display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                            justifyContent: 'space-between'
                        }}>
                            <span>Estimated CPM:</span>
                            <span>{displayedCpm}</span>


                        </Box>

                        {/*item.externalSegments?.map(segment => {
                            return <Box key={segment.uuid}>
                                <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                                    {segment.segmentName}
                                </Typography>
                                <SegmentIdsDetails segment={segment}/>
                            </Box>

                        })*/}
                    </Box>
                })
                }
            </Box>

        })}
    </Box>
}

const IdsDetails = (props: { idCount: IdCount[] }) => {
    const {idCount} = props
    const [displayDetails, setDisplayDetails] = React.useState<boolean>(false)
    return <Box>
        <Box sx={{
            mx: 3, cursor: 'pointer', display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            justifyContent: 'space-between'
        }} onClick={() => setDisplayDetails(!displayDetails)}>
            <span>Max IDs Volume:</span> <span>{profilesFormatter.format(getMaxFromIdsCountList(idCount))}</span>


        </Box>
        <Collapse in={displayDetails}>
            <Box sx={{
                background: "#fafafa",
                padding: '0.5rem',
                borderRadius: '7px',
                border: '1px solid #ececec',
                ml: 3,
                mr: 3
            }}>
                {idCount.map((id) => {
                    return <Box key={id.idName}
                                sx={{
                                    display: "flex",
                                    gap: "0.5rem",
                                    alignItems: "center",
                                    justifyContent: 'space-between'
                                }}>
                        <Typography variant="body1" sx={{fontWeight: 'bold', minWidth: '110px'}}>
                            {getIdName(id.idName)}:
                        </Typography>
                        <Typography variant="body1">{profilesFormatter.format(id.total)}</Typography>
                    </Box>
                })}
            </Box>
        </Collapse>
    </Box>
}