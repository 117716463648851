import React from "react";
import {Button, Skeleton} from "@mui/material";
import {IAudiences} from "application/entities/dataTypes/audiences";
import {audiencesColumns} from "application/entities/dataColumns/audiences.columns";
import _ from "lodash";
import {GridTable} from "components/organisms/GridTable";
import {dataColumnModelUtils, TDataColumnModel} from "application/utils/dataColumnModel.utils";
import {GridValueGetterParams} from "@mui/x-data-grid";
import {IOpenDialogAction} from "redux/slices/dialog.slices";
import {AudiencePreviewPopup} from "pages/Audiences/List/AudienceLibraryThumb/audiencePreviewPopup";
import {useOpenDialogWindow} from "components/live/DialogWindow";
import {useNavigate} from "react-router";
import {pageUtils} from "application/pages/pages.utils";
import {PageId} from "application/pages/pages.config";
import {FloatingMenuHandle} from "pages/Audiences/Card/FloatingMenuHandle";
import {AppIcon} from "components/ui/AppIcon";
import {AppDrawer} from "components/ui/AppDrawer";
import {ProviderStatus} from "pages/Audiences/Card/ProviderStatus";
import {AudienceCard} from "pages/Audiences/Card";
import {objectApi} from "application/entities/dataApi";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import {BackendError} from "types/backendError";
import {AppTabs} from "components/ui/Tabs";

export const LastAudiencesTable = () => {
    const windowDialog = useOpenDialogWindow();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const audiencesApi = new objectApi.audiences();

    //transparency card
    const [visibleDrawer, setVisibleDrawer] = React.useState<boolean>(false);
    const [menuMode, setMenuMode] = React.useState<"transparency" | "channels" | "enablement" | undefined>("transparency");
    const [activeAudience, setActiveAudience] = React.useState<IAudiences>({} as any);
    const [tcLoading, setTcLoading] = React.useState<boolean>(false);
    const [activeTab, setActiveTab] = React.useState<undefined | "DRAFT" | "PENDING_APPROVAL" | "APPROVED" | "ACTIVATED" | "ARCHIVED">(undefined);

    const audiences = useQuery<any, BackendError>(
        ["audiences-admin-dashboard", "audiences", `audiences-${activeTab}`],
        () => {
            return audiencesApi
                .search({
                    query: {status: activeTab},
                    page: {pageSize: 30, pageNumber: 0},
                    sort: {sortBy: "id", sortDir: "desc"},
                })
                .then((res) => res);
        },
        {cacheTime: 60 * 60 * 1000, staleTime: 0}
    );

    const tabs = [
        {id: 0, text: `${t("audience.statusAll")}`, value: undefined},
        {id: 1, text: `${t("audience.statusDraft")}`, value: "DRAFT"},
        {id: 2, text: `${t("audience.pendingApproval")} `, value: `PENDING_APPROVAL`},
        {id: 3, text: `${t("audience.approved")} `, value: `APPROVED`},
        {id: 4, text: `${t("audience.statusActivated")} `, value: `ACTIVATED`},
        {id: 5, text: `${t("audience.statusArchived")} `, value: `ARCHIVED`},
    ];
    const flatData: IAudiences[] = audiences?.data?.data?.detailedResults?.length
        ? audiences?.data?.data?.detailedResults[0]?.result?.content?.flat()
        : [];
    const columns = _.cloneDeep(audiencesColumns());

    const cols: TDataColumnModel = {
        id: columns.id,
        createdDate: columns.createdDate,
        company: columns.company,
        // creator: columns.creator,
        name: columns.name,
        status: columns.status,
        lastAudienceSize: columns.lastAudienceSize,
        CPM: columns.cpm,
        actions: columns.actions,
    };
    cols.createdDate.sortable = false;
    cols.company.sortable = false;
    //cols.creator.sortable = false
    cols.name.sortable = false;
    cols.status.sortable = false;
    cols.lastAudienceSize.sortable = false;
    cols.id.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
        return (
            <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
                #{cell.row.id}
                <AppIcon
                    onClick={() => {
                        openTransparencyCard(cell.row.id, cell.row.audienceStatus);
                    }}
                    dataCy={"audienceTransparencyCardIcon"}
                    display={"inline"}
                    fontSize={"small"}
                    icon={"FileSearch"}
                    title={"Open Transparency card"}
                />
            </div>
        );
    };

    cols.company.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
        return (
            <p
                style={{cursor: "pointer", color: "#00b5e2"}}
                title={cell.row.account.companyName}
                onClick={() => {
                    navigate(pageUtils.getPagePathById(PageId.adminAccountView, {accountId: cell.row.account?.id}));
                }}
            >
                {cell.row.account.companyName}
            </p>
        );
    };

    cols.actions.width = 140;
    cols.actions.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
        return (
            <div style={{display: "flex", gap: "10px"}}>
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                        handlePreview(cell.row.id);
                    }}
                >
                    Preview
                </Button>
                {["ARCHIVED", "ACTIVATED", "DRAFT"].includes(cell.row.audienceStatus) && (
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => {
                            handleEditView(cell.row.id, cell.row.audienceStatus);
                        }}
                    >
                        {cell.row.audienceStatus === "DRAFT" ? "Edit" : "View"}
                    </Button>
                )}
            </div>
        );
    };

    const audienceColumns = dataColumnModelUtils.renderModel(cols, "show");

    const handlePreview = (audienceId: number) => {
        const previewDialog: IOpenDialogAction = {
            dialogId: "AudiencePreviewPopup",
            component: AudiencePreviewPopup,
            componentProps: {
                audienceId: audienceId,
                previewMode: true,
                hideButtons: true,
                onDelete: () => {
                },
            },
            dialogProps: {
                className: "previewAudiencePopup",
            },
        };
        windowDialog.open(previewDialog).then();
    };

    const handleEditView = (audienceId: number, status: string) => {
        if (status === "DRAFT") {
            navigate(pageUtils.getPagePathById("audience-builder-update", {id: audienceId}));
        } else if (["ARCHIVED", "ACTIVATED"].includes(status)) {
            navigate(pageUtils.getPagePathById("audience-view", {audienceId: audienceId}));
            return;
        }
    };

    function setDrawerMode(mode: "transparency" | "channels" | "enablement") {
        if (mode === menuMode) {
            /*if (visibleDrawer) setMenuMode(undefined);
             setVisibleDrawer(!visibleDrawer);*/
        } else {
            setMenuMode(mode);
            setVisibleDrawer(true);
        }
    }

    function handleToggleDrawer(fact: any) {
        if (fact && !menuMode) setMenuMode("transparency");
        setVisibleDrawer(fact);
    }

    function openTransparencyCard(audienceId: number, audienceStatus: string) {
        if (!activeAudience || audienceId === activeAudience.id) {
            setVisibleDrawer(false);
            setActiveAudience({} as any);
        } else {
            setTcLoading(true);
            setVisibleDrawer(true);
            audiencesApi.byId(audienceId).then((res) => {
                setActiveAudience(res);
                setTcLoading(false);
            });
        }
    }

    let provStatus = visibleDrawer ? {right: "340px", top: "0.5rem"} : {right: 0, top: 0};
    const drawerTitle =
        menuMode === "channels"
            ? t("audience.cardTips.channelTipTitle")
            : menuMode === "enablement"
                ? t("audience.cardTips.enablementTipTitle")
                : t("audience.cardTips.transparencyTipTitle");
    const drawerTip =
        menuMode === "channels"
            ? t("audience.cardTips.channelTip")
            : menuMode === "enablement"
                ? t("audience.cardTips.enablementTip")
                : t("audience.cardTips.transparencyTip");

    return (
        <>
            {audiences.isLoading ? (
                <>
                    {Array.from(Array(8).keys()).map((i) => {
                        return <Skeleton key={i} variant="rectangular" width={"100%"} height={"38px"}
                                         style={{borderRadius: "7px", marginBottom: "5px"}}/>;
                    })}
                </>
            ) : (
                <>
                    <AppTabs
                        tabList={tabs}
                        onClick={(tab) => {
                            setActiveTab(tab.value);
                        }}
                    ></AppTabs>
                    <GridTable
                        styleClass={"naked"}
                        customStyle={{height: "350px"}}
                        data={{
                            columns: audienceColumns as any,
                            rows: flatData,
                        }}
                        gridProps={{
                            disableSelectionOnClick: true,
                            autoHeight: false,
                            hideFooter: true,
                            columnBuffer: 30,
                            columnThreshold: 10,
                        }}
                    />
                </>
            )}

            <FloatingMenuHandle hide={!visibleDrawer} position={provStatus}>
                <div data-icon-button-zone className={"pad flex-v gap-tiny"}>
                    <button style={{width: "24px"}} data-cy={"btnActionToggleAudienceDrawer"}
                            onClick={() => handleToggleDrawer(!visibleDrawer)}>
                        <AppIcon
                            themeColor={!visibleDrawer ? "primary" : "secondary"}
                            fontSize={"small"}
                            icon={"ChevronDownIcon"}
                            rotate={visibleDrawer ? -90 : 90}
                        />
                    </button>
                    <button data-cy={"btnActionOpenTransparencyCard"} onClick={() => setDrawerMode("transparency")}>
                        <AppIcon themeColor={menuMode === "transparency" ? "primary" : "secondary"} fontSize={"small"}
                                 icon={"FileSearch"}/>
                    </button>
                    {["DRAFT"].includes(activeAudience.audienceStatus) && (
                        <button style={{width: "24px"}} data-cy={"btnActionOpenChannels"}
                                onClick={() => setDrawerMode("channels")}>
                            <AppIcon themeColor={menuMode === "channels" ? "primary" : "secondary"}
                                     icon={"ChannelsNav"}/>
                        </button>
                    )}
                    {!["DRAFT"].includes(activeAudience.audienceStatus) && (
                        <button style={{width: "24px"}} data-cy={"btnActionOpenEnablement"}
                                onClick={() => setDrawerMode("enablement")}>
                            <AppIcon themeColor={menuMode === "enablement" ? "primary" : "secondary"} fontSize={"small"}
                                     icon={"Channels"}/>
                        </button>
                    )}
                </div>
            </FloatingMenuHandle>
            <AppDrawer
                title={drawerTitle}
                helperText={drawerTip}
                helperTitle={drawerTitle}
                open={visibleDrawer}
                loading={tcLoading}
                hideHandle={true}
                actionButtonsVars={{...activeAudience, audienceId: activeAudience?.id}} // add audienceId
                onClose={() => {
                    handleToggleDrawer(false);
                }}
            >
                {menuMode === "channels" && <ProviderStatus audienceData={activeAudience} position={provStatus}
                                                            isSegmentAudience={activeAudience?.audienceType?.code === "SEGMENTS"}/>}
                {menuMode !== "channels" && <AudienceCard menuMode={menuMode} audience={activeAudience}/>}
            </AppDrawer>
        </>
    );
};
