import React from "react";
import {Box, Typography} from "@mui/material";

export const SearchItem = (props: { title: string, children: React.ReactNode, icon?: React.ReactNode }) => {
    return <>
        <Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                mb: '0.35rem'
            }}>
                {props.icon}
                <Typography variant="body1" fontSize="13px" color="#5c6a7f" sx={{fontWeight: 'bold'}}>
                    {props.title}
                </Typography>

            </Box>
            {props.children}
        </Box>
    </>
}