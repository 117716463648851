import {create} from "zustand";
import {mountStoreDevtool} from "simple-zustand-devtools";
import {objectApi} from "../../application/entities/dataApi";
import {IExternalSegment} from "../../application/entities/dataTypes/externalSegment";
import axios, {CancelTokenSource} from "axios";
import {AUTHUser} from "../../application/utils/AuthUser";
import {DspCampaignInfo} from "../../application/entities/dataTypes/dspCampaignInfo";
import {calculateCpmForSegment, DEFAULT_DISTANCE} from "./Segments.utils";


export type SearchType = 'keyword' | 'nearText' | 'hybrid' | 'strictKeywords'

type SegmentBuilderStoreType = {
    accountId?: number
    agencyAdditionalCpm: number
    audienceId?: number
    audienceName: string
    cancelToken?: CancelTokenSource;
    channelsSelected?: string[]
    countriesList: Array<{ value: string, label: string }>
    countryCode: string[]
    currency: string
    currencyRate: number
    displaySegmentSelected: boolean
    displaySidePanel: boolean
    distance: number
    dspCampaignInfo?: DspCampaignInfo[]
    error?: string
    getCountryLabelByCode: (code: string) => string
    getCpm: (cpm: number) => number
    getProviderLabelByCode: (code: string) => string
    holdCoCpmMultiplier: number
    init: (audienceId: number) => void
    isLoading: boolean
    limit: number
    page: number
    providerCode: string[]
    providersList: Array<{ value: string, label: string }>
    query?: string
    reset: () => void;
    searchDone: boolean
    searchType: SearchType
    selectedSegments: IExternalSegment[]
    sidebarLoading: boolean
    updateSelectedSegments: (segments: IExternalSegment[]) => void
    updateAudienceName: (name: string) => void
};

const accountsApi = new objectApi.accounts();
const audienceApi = new objectApi.audiences();


const initialState: Omit<SegmentBuilderStoreType, 'init' | 'reset' | 'getCountryLabelByCode' | 'getProviderLabelByCode' | 'updateSelectedSegments' | 'updateAudienceName'> = {
    currency: 'USD',
    audienceName: 'Loading...',
    isLoading: true,
    currencyRate: 1,
    agencyAdditionalCpm: 0,
    holdCoCpmMultiplier: 1,
    sidebarLoading: false,
    query: undefined,
    searchType: 'nearText',
    displaySegmentSelected: false,
    distance: DEFAULT_DISTANCE,
    limit: 50,
    page: 0,
    countryCode: [],
    providerCode: [],
    countriesList: [{
        value: '',
        label: 'Loading Countries'
    }],
    selectedSegments: [],
    providersList: [{
        value: '',
        label: 'Loading Providers'
    }],
    searchDone: false,
    displaySidePanel: false,
    getCpm: (cpm: number) => cpm

};


export const useSegmentBuilderStore = create<SegmentBuilderStoreType>((set, get) => ({
    ...initialState,
    init: (audienceId: number) => {
        console.log('init', audienceId)

        audienceApi.byId(audienceId).then((res) => {
            const accountId = res.account.id
            console.log('audience', res)
            set({
                audienceId: audienceId,
                audienceName: res.name,
                query: "",
                selectedSegments: convertSegmentAudienceFeatureValuesToObject(res.audienceFeatures[0]?.featureValues ?? []),
                currency: res.displayCurrency ?? 'USD',
                accountId: +accountId,
                searchDone: res.audienceFeatures[0]?.featureValues ? true : false,
                displaySegmentSelected: res.audienceFeatures[0]?.featureValues ? true : false,
                dspCampaignInfo: res.dspCampaignInfo,
                displaySidePanel: false
            })

            accountsApi.getSegmentsParameters(res.account.id).then((res) => {
                const currencyRate = res.data.currencyConversionRate ?? 1
                const agencyAdditionalCpm = res.data.agencyAdditionalCpm ?? 0
                const holdCoCpmMultiplier = res.data.holdCoCpmMultiplier ?? 1

                set({
                    currencyRate: currencyRate,
                    agencyAdditionalCpm: agencyAdditionalCpm,
                    holdCoCpmMultiplier: holdCoCpmMultiplier
                })
            })
            console.log('audience', res)
            //get providers
            accountsApi.getSegmentsProviders(+accountId).then((providers) => {
                const accountProviders = providers.data.providers.map((provider: any) => {
                    return {
                        value: provider.providerCode,
                        label: provider.providerName
                    }
                })
                set({
                    providersList: [
                        {value: '', label: 'All Providers'},
                        ...accountProviders
                    ]
                })
            })

            AUTHUser.setCurrentBrandId(+accountId)

            //get countries
            accountsApi.getSegmentsCountries(+accountId).then((countries) => {
                const accountCountries = countries.data.countries.map((country: any) => {
                    return {
                        value: country.code,
                        label: country.name
                    }
                })
                set({
                    countriesList: [
                        {value: '', label: 'All Countries'},
                        ...accountCountries
                    ]
                })


            })
            set({isLoading: false})
        }).catch(e => console.log(`Error - get audience ${audienceId}`, e))
    },
    reset: () => set(initialState),

    getCountryLabelByCode: (code: string) => {
        return get().countriesList.find((country) => country.value === code)?.label ?? code;
    },

    getProviderLabelByCode: (code: string) => {
        return get().providersList.find((provider) => provider.value === code)?.label ?? code;
    },

    updateSelectedSegments: (segments: IExternalSegment[]) => {
        set({selectedSegments: segments, sidebarLoading: true})

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = get().cancelToken
        if (token) {
            token.cancel("Operation canceled by the user.");
        }
        set({cancelToken: source})
        console.log('updateAudienceSegments', segments)
        const featureValues = segments.length > 0 ? convertSegmentForApiCall(segments) : []
        //if no values, delete feature
        if (featureValues.length === 0) {
            const audienceFeaturesApi = new objectApi.audienceFeatures();
            console.log('delete feature')
            audienceApi.byId(get().audienceId!).then((res: any) => {
                console.log('audience', res)
                const featureId = res.audienceFeatures[0].id
                console.log('featureId', featureId)
                audienceFeaturesApi.delete(featureId).then((res) => {
                    set({sidebarLoading: false, displaySidePanel: false, dspCampaignInfo: []})
                })
            }).catch(e => {
                console.log('error', e)
                set({sidebarLoading: false})
            })
        } else {
            audienceApi.createUpdateAudienceFeature(get().audienceId!, 43, {featureValues: featureValues}, source.token).then((res: any) => {
                console.log('res', res)
                console.log('converted feature', convertSegmentAudienceFeatureValuesToObject(res.data.audienceFeatures[0].featureValues))
                console.log('selectedSegments', get().selectedSegments)
                set({
                    selectedSegments: convertSegmentAudienceFeatureValuesToObject(res.data.audienceFeatures[0].featureValues),
                    dspCampaignInfo: res.data.dspCampaignInfo,
                    sidebarLoading: false
                })

            }).catch(e => {
                console.log('error', e)
                set({sidebarLoading: false})
            })

        }


    },

    getCpm: (cpm: number) => {
        const {currencyRate, agencyAdditionalCpm, holdCoCpmMultiplier} = get()
        return calculateCpmForSegment(cpm, holdCoCpmMultiplier, agencyAdditionalCpm, currencyRate)

    },

    updateAudienceName: async (name) => {
        set({
            isLoading: true
        })
        const audienceId = get().audienceId;
        if (!audienceId) return;
        const audienceApi = new objectApi.audiences();

        audienceApi.update(audienceId, {name}).then((newAudience) => {
            if (newAudience) set({audienceName: newAudience.name, isLoading: false});
        }).catch(e => {
            set({
                isLoading: false
            })
        });
    },


}));


const convertSegmentForApiCall = (segments: IExternalSegment[]) => {
    return segments.map(segment => {
        return JSON.stringify(segment)
    })
}

const convertSegmentAudienceFeatureValuesToObject = (featureValues: Array<any>) => {
    if (featureValues.length === 0) return []
    return featureValues.map(featureValue => {
        return JSON.parse(featureValue)
    })
}

if (process.env.NODE_ENV === "development") {
    mountStoreDevtool("useSegmentBuilderStore", useSegmentBuilderStore);
}
