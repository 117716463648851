import {useSegmentBuilderStore} from "./SegmentBuilderStore";
import React, {useEffect, useMemo} from "react";
import {useQuery} from "react-query";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {Button, Checkbox, LinearProgress, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {SegmentIdsDetails} from "./SegmentIdsDetails";
import {objectApi} from "../../application/entities/dataApi";
import Paper from "@mui/material/Paper";
import {SegmentBuilderFooter} from "./footer/SegmentBuilderFooter";
import {IExternalSegment} from "../../application/entities/dataTypes/externalSegment";
import {moneyFormatter} from "../../application/utils/formatters.utils";

const segmentsApi = new objectApi.externalSegments();


export const SegmentsResults = () => {
    const segmentBuilderStore = useSegmentBuilderStore()

    const query = segmentBuilderStore.query
    const searchType = segmentBuilderStore.searchType
    const countryCode = segmentBuilderStore.countryCode
    const providerCode = segmentBuilderStore.providerCode
    const limit = segmentBuilderStore.limit
    const distance = segmentBuilderStore.distance
    const currency = segmentBuilderStore.currency
    const getCpm = segmentBuilderStore.getCpm
    const page = segmentBuilderStore.page
    const displaySegmentSelected = useSegmentBuilderStore((state) => state.displaySegmentSelected)
    const selectedSegments = useSegmentBuilderStore(st => st.selectedSegments)
    const searchDone = segmentBuilderStore.searchDone

    const {
        isLoading,
        isFetching,
        error,
        data,
        refetch
    } = useQuery(['externalSegments', `segment-${query}-${searchType}-${countryCode}-${providerCode}-distance-${distance}-page-${page}`], () => {
        if (query?.length) {
            useSegmentBuilderStore.setState({searchDone: true})
        }
        if (query?.length === 0) {
            return segmentsApi.listSegments({
                limit,
                offset: page * limit,
                filters: {
                    countryCode: countryCode.length ? countryCode : undefined,
                    providerCode: providerCode.length ? providerCode : undefined,
                }
            })

        }
        return segmentsApi.searchSegments({
            query: query || '',
            limit,
            type: searchType,
            distance,
            filters: {
                countryCode: countryCode.length ? countryCode : undefined,
                providerCode: providerCode.length ? providerCode : undefined,
            }
        })
    }, {
        cacheTime: 30000,
        retry: 3
    });

    //set dats depending on search or list segment
    const datas = data?.data ?? data
    const total = data?.count ?? 0


    useEffect(() => {
        if (selectedSegments.length === 0) {
            useSegmentBuilderStore.setState({displaySidePanel: false})
        }
    }, [selectedSegments])

    useEffect(() => {
        useSegmentBuilderStore.setState({sidebarLoading: isLoading})
    }, [isLoading])


    const handleSegmentSelectChange = (segment: IExternalSegment, checked: boolean) => {
        let newSelectedSegments = [...selectedSegments]
        if (checked) {
            newSelectedSegments = [...newSelectedSegments, segment]
        } else {
            newSelectedSegments = newSelectedSegments.filter(selected => selected.uuid !== segment.uuid)
        }
        useSegmentBuilderStore.getState().updateSelectedSegments(newSelectedSegments)
        if (newSelectedSegments?.length === 0) {
            useSegmentBuilderStore.setState({displaySidePanel: false})
        }
    }

    const isSegmentSelected = (segment: IExternalSegment) => {
        const selected = selectedSegments.find(selected => selected.uuid === segment.uuid)
        return Boolean(selected)
    }

    const columns = useMemo(() => {
        const cols: GridColDef[] = [{
            field: 'select',
            headerName: '',
            flex: 0,
            width: 40,
            disableReorder: true,
            sortable: false,
            renderCell: (params) => {
                return <Checkbox checked={isSegmentSelected(params.row)} onChange={(e: any) => {
                    handleSegmentSelectChange(params.row, e.target.checked)

                }}/>
            }
        },
            /* {
                 field: 'metaData',
                 headerName: 'Score',
                 width: 60,
                 flex: 0,
                 renderCell: (params) => {
                     if (!params.row.metaData?.score) {
                         return <>-</>
                     }
                     let scoreDisplayed = params.row.metaData?.score > 0 ? params.row.metaData?.score : params.row.metaData?.distance
                     return <>{Math.round(scoreDisplayed * 100) / 100}</>
                 }
             }, */
            {
                field: 'name', sortable: query?.length !== 0, headerName: 'Segment', flex: 1, renderCell: (params) => {

                    return <Box sx={{display: "flex", flexDirection: 'column'}} py={1}>
                        <Typography variant="body1" fontWeight={900}
                                    title={params.row.segmentName}>{params.row.segmentName}</Typography>
                        <Typography variant="body2" color="#666"
                                    title={params.row.segmentDescription}>{params.row.segmentDescription}</Typography>
                    </Box>
                }
            },
            {
                field: 'segmentCpm',
                headerName: 'CPM',
                flex: 0,
                sortable: query?.length !== 0,
                renderCell: (params) => moneyFormatter(getCpm(params.row.segmentCpm), {currency: currency})
            },
            {
                field: 'ids',
                headerName: 'IDs Volume Max',
                sortable: false,
                flex: 0,
                width: 120,
                renderCell: (params) => <SegmentIdsDetails segment={params.row}/>


            },
            {
                field: 'countryCode',
                headerName: 'Country',
                flex: 0,
                renderCell: (params) => <>{segmentBuilderStore.getCountryLabelByCode(params.row.countryCode)}</>
            },
            {
                field: 'providerCode',
                headerName: 'Provider',
                flex: 0,
                renderCell: (params) => <>{segmentBuilderStore.getProviderLabelByCode(params.row.providerCode)}</>
            },
        ]
        return cols;
    }, [selectedSegments, query])


    return <>
        {searchDone && (
            <Box sx={{
                display: 'flex',
                gap: '1rem',
                flexDirection: 'column',
                height: 'calc(100vh - 380px)',
                justifyContent: 'space-between'
            }}>
                <Paper sx={{height: 900, width: '100%', background: "#fff"}}>
                    <DataGrid
                        loading={isFetching}
                        error={error && <>Error, try again</>}
                        rows={displaySegmentSelected ? selectedSegments : datas ?? []}
                        columns={columns}
                        getRowId={(row) => row.uuid}
                        pageSize={limit}
                        rowsPerPageOptions={[limit]}
                        components={{
                            NoRowsOverlay: NoResults,
                            LoadingOverlay: LinearProgress,
                            ErrorOverlay: ErrorMessage,
                        }}
                        componentsProps={{
                            errorOverlay: {refetch: refetch, error: error}
                        }}
                        hideFooter={query?.length !== 0 || displaySegmentSelected}
                        hideFooterPagination={query?.length !== 0}
                        rowCount={total ?? 0}
                        disableColumnMenu
                        disableColumnSelector
                        disableColumnFilter
                        disableSelectionOnClick
                        getRowHeight={() => 'auto'}
                        paginationMode="server"
                        onPageChange={(page) => {
                            console.log('page', page)
                            useSegmentBuilderStore.setState({page: page})
                        }}
                        sx={{
                            border: 0,
                            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                display: "none"
                            },
                            "& .MuiTablePagination-select": {
                                paddingRight: '25px !important'
                            }
                        }}
                    />

                </Paper>

                <SegmentBuilderFooter/>
            </Box>
        )}
    </>
}

const ErrorMessage = (props: any) => {
    console.log(props?.error)
    return <Box sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    }}>
        Error, try again
        <Button onClick={props?.refetch}>Retry</Button>
    </Box>
}

const NoResults = () => {
    const query = useSegmentBuilderStore(st => st.query)
    const searchType = useSegmentBuilderStore(st => st.searchType)
    const distance = useSegmentBuilderStore(st => st.distance)


    let displayMessage = ''
    if (query?.length === 0) {
        displayMessage = 'You need to enter a search query to display the segments'
    } else {
        displayMessage = `No results found for "${query}"`
    }

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        gap: "0.5rem",
        zIndex: 1000,
        position: 'relative',
    }}>
        <Typography variant="h5" sx={{fontWeight: 900}}>
            {displayMessage}
        </Typography>
        {(searchType === "keyword" && query) && <Box>
            <Button variant="contained" onClick={() => {
                useSegmentBuilderStore.setState({searchType: 'nearText'})
            }}>
                Try Natural Language Search
            </Button>
        </Box>}

        {(searchType === "nearText" && query) && <Box>
            <Button variant="contained" onClick={() => {
                useSegmentBuilderStore.setState({distance: distance + 0.01})
            }}>
                Increase Creativity
            </Button>
        </Box>}
    </Box>
}