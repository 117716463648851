import {AbstractBaseApi} from './AbstractBaseApi';
import {IApiOptions} from '../api/apiService';
import {IExternalSegment} from "../dataTypes/externalSegment";
import {segmentApiUrl} from "../../../_configuration/api.config";


export class AudienceSegment extends AbstractBaseApi<IExternalSegment> {
    constructor(options?: IApiOptions) {
        super('externalSegments', options);
    }


    searchSegments(props: {
        query: string,
        limit?: number,
        type?: 'keyword' | 'nearText' | 'hybrid' | 'nearVector' | 'strictKeywords'
        distance?: number
        filters?: {
            countryCode?: string[]
            providerCode?: string[]
        }
    }) {
        const {query, limit, type, distance, filters} = props;
        const key = "21eeec97-4a18-427c-a31a-74aff0b550e7"
        let queryParams: string = `?key=${key}&query=${query}`;
        if (limit) queryParams += `&limit=${limit}`;
        if (type) queryParams += `&type=${type}`;
        if (distance) queryParams += `&distance=${distance}`;
        if (filters?.countryCode) queryParams += `&countryCode=${filters.countryCode}`;
        if (filters?.providerCode) queryParams += `&providerCode=${filters.providerCode}`;

        if (query.length === 0) {
            return []
        }


        return fetch(`${segmentApiUrl}/api/search-segments${queryParams}`).then(reqRes => {
            return reqRes.json().then(res => {
                if (res.results?.objects) {
                    return res.results.objects.map((segment: any) => {
                            return {...segment.properties, metaData: segment.metadata}
                        }
                    )
                }
                return []
            }).catch(e => {
                console.log(e)
                console.log(reqRes)
                throw new Error("Error with segment api response")
            })

        }).catch(e => {
            console.log(e)
            throw new Error("Error with segment api")
        })

    }

    listSegments(props: {
        limit?: number,
        offset?: number,
        filters?: {
            countryCode?: string[]
            providerCode?: string[]
        }
    }) {
        const {limit, offset, filters} = props
        const key = "21eeec97-4a18-427c-a31a-74aff0b550e7"
        let queryParams: string = `?key=${key}&limit=${limit ?? 50}&offset=${offset ?? 0}`
        if (filters?.countryCode) queryParams += `&countryCode=${filters.countryCode}`;
        if (filters?.providerCode) queryParams += `&providerCode=${filters.providerCode}`;

        return fetch(`${segmentApiUrl}/api/list-segments${queryParams}`).then(reqRes => {
            return reqRes.json().then(res => {
                const count = res?.results?.count?.totalCount ?? 0

                const data = res?.results?.data?.objects?.map((segment: any) => {
                        return {...segment.properties, metaData: segment.metadata}
                    }
                )
                console.log({
                    count: count,
                    data: data ?? []
                })
                return {
                    count: count,
                    data: data ?? []
                }
            }).catch(e => {
                console.log(e)
                console.log(reqRes)
                throw new Error("Error with segment api response")

            })
        }).catch(e => {
            console.log(e)
            throw new Error("Error with segment api")
        })
    }


}