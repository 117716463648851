import {IExternalSegment} from "../../application/entities/dataTypes/externalSegment";
import React, {ReactNode} from "react";
import {profilesFormatter} from "../../_configuration/formaters";
import {Box, IconButton, Tooltip, Typography} from "@mui/material";
import {AppIcon} from "../../components/ui/AppIcon";
import {getTotalIds} from "./Segments.utils";

export const SegmentIdsDetails = (props: { segment: IExternalSegment }) => {

    return <Box sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 32px',
        alignItems: 'center',
        gap: '0.25rem',
        justifyContent: "end",
        width: "100%"
    }}>
        <Typography>
            {profilesFormatter.format(getTotalIds(props.segment))}
        </Typography>
        <Tooltip title={<TootTipContent segment={props.segment}/>}>
            <IconButton>
                <AppIcon icon="InfoIcon" color="#7587A3" fontSize="small"/>
            </IconButton>
        </Tooltip>
    </Box>

}

const TootTipContent = (props: { segment: IExternalSegment }) => {
    const getIdsVolume = (segment: IExternalSegment) => {
        const res: ReactNode[] = []
        if (segment?.numHems && segment.numHems > 0) {
            res.push(<><span>Emails:</span> {profilesFormatter.format(segment.numHems)}</>)
        }
        if (segment?.numAndroidids && segment.numAndroidids > 0) {
            res.push(<><span>Android Ids:</span> {profilesFormatter.format(segment.numAndroidids)}</>)
        }
        if (segment?.numId5s && segment.numId5s > 0) {
            res.push(<><span>Id5:</span> {profilesFormatter.format(segment.numId5s)}</>)
        }
        if (segment?.numIosids && segment.numIosids > 0) {
            res.push(<><span>Ios Ids:</span> {profilesFormatter.format(segment.numIosids)}</>)
        }
        if (segment?.numIps && segment.numIps > 0) {
            res.push(<><span>Ips:</span> {profilesFormatter.format(segment.numIps)}</>)
        }
        if (segment?.numPanoids && segment.numPanoids > 0) {
            res.push(<><span>PanoID:</span> {profilesFormatter.format(segment.numPanoids)}</>)
        }
        if (segment?.numPubmaticCookies && segment.numPubmaticCookies > 0) {
            res.push(<><span>Pubmatic Cookies:</span> {profilesFormatter.format(segment.numPubmaticCookies)}</>)
        }
        if (segment?.numRampids && segment.numRampids > 0) {
            res.push(<><span>Ramp Ids:</span> {profilesFormatter.format(segment.numRampids)}</>)
        }
        if (segment?.numTtdids && segment.numTtdids > 0) {
            res.push(<><span>The Trade Desk Ids:</span> {profilesFormatter.format(segment.numTtdids)}</>)
        }
        if (segment?.numUid20s && segment.numUid20s > 0) {
            res.push(<><span>Uid2.0:</span> {profilesFormatter.format(segment.numUid20s)}</>)
        }
        return res
    }
    return <Box>{getIdsVolume(props.segment).map((item, idx) => <Box sx={{
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '0.5rem',
        py: '0.15rem',


    }} key={idx}>{item}</Box>)}
    </Box>
}