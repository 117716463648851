import {Box, Button, TextField} from "@mui/material";
import {GridSortModel, GridValueGetterParams} from "@mui/x-data-grid";
import {objectApi} from "application/entities/dataApi";
import {audiencesColumns} from "application/entities/dataColumns/audiences.columns";
import {IAudiences} from "application/entities/dataTypes/audiences";
import {PageId} from "application/pages/pages.config";
import {pageUtils} from "application/pages/pages.utils";
import {dataColumnModelUtils, TDataColumnModel, TDataRowModel} from "application/utils/dataColumnModel.utils";
import {useAudiencesAlwaysOn, useSearchAudiences} from "components/hooks/data/useAudiencesQuery";
import {useAdminPage} from "components/hooks/useAdminPage";
import {useOpenDialogWindow} from "components/live/DialogWindow";
import {GridTable} from "components/organisms/GridTable";
import {MainFrame} from "components/templates/MainFrame";
import {AppDrawer} from "components/ui/AppDrawer";
import {H4} from "components/ui/AppElements";
import {AppIcon} from "components/ui/AppIcon";
import {AppTabs} from "components/ui/Tabs";
import _ from "lodash";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {IOpenDialogAction} from "redux/slices/dialog.slices";
import styled from "styled-components/macro";
import useLocalStorageState from "use-local-storage-state";
import {AudienceCard} from "../Card";
import {FloatingMenuHandle} from "../Card/FloatingMenuHandle";
import {ProviderStatus} from "../Card/ProviderStatus";
import {AudiencePreviewPopup} from "../List/AudienceLibraryThumb/audiencePreviewPopup";
import {AlwaysOnDialog} from "./AlwaysOnDialog";

import {AdminBanner, AdminWrap} from "pages/EvorraDashboard";

const audiencesApi = new objectApi.audiences();

export const AdminAudiencesList = () => {
    const [pageConfig, setPageConfig] = useLocalStorageState<{
        pageSize: number;
        pageNumber: number;
    }>("adminAudience-pageConfig", {
        pageSize: 50,
        pageNumber: 0,
    });

    const [sortModel, setSortModel] = useLocalStorageState<GridSortModel>("adminAudience-sortModel", [{
        field: "creationTime",
        sort: "desc"
    }]);
    //transparency card
    const [visibleDrawer, setVisibleDrawer] = React.useState<boolean>(false);
    const [menuMode, setMenuMode] = React.useState<"transparency" | "channels" | "enablement" | undefined>("transparency");
    const [activeAudience, setActiveAudience] = React.useState<IAudiences>({} as any);
    const [tcLoading, setTcLoading] = React.useState<boolean>(false);
    const [audienceManualStep, setAudienceManualStep] = React.useState<number>(0);

    const {t} = useTranslation();
    useAdminPage();

    useEffect(() => {
        audiencesApi.getAudiencesManualStep().then((res) => {
            setAudienceManualStep(res.data._embedded.audiences?.length ?? 0);
        });
    }, []);

    const [status, setStatus] = React.useState<undefined | "DRAFT" | "PENDING_APPROVAL" | "APPROVED" | "ACTIVATED" | "ARCHIVED">(undefined);
    const [inputSearch, setInputSearch] = React.useState<string>("");

    const inputSearchField = React.useRef(null);
    const timerSearchField: any = React.useRef();

    const {isLoading, error, data, refetch} = useSearchAudiences({
        page: pageConfig,
        sort: {
            sortBy: sortModel[0].field,
            sortDir: sortModel[0].sort ?? "asc",
        },
        query: {
            ...(status ? {status: status} : {}),
            ...(inputSearch ? {kw: inputSearch} : {}),
        },
    });

    const tabs = [
        {id: 0, text: `${t("audience.statusAll")}`, value: undefined},
        {id: 1, text: `${t("audience.statusDraft")}`, value: "DRAFT"},
        {id: 2, text: `${t("audience.pendingApproval")} `, value: `PENDING_APPROVAL`},
        {id: 3, text: `${t("audience.approved")} `, value: `APPROVED`},
        {id: 4, text: `${t("audience.statusActivated")} `, value: `ACTIVATED`},
        {id: 5, text: `${t("audience.statusArchived")} `, value: `ARCHIVED`},
    ];

    const handleTabChange = (tab: any) => {
        setPageConfig({
            pageSize: 50,
            pageNumber: 0,
        });
        setSortModel([
            {
                field: "creationTime",
                sort: "desc",
            },
        ]);
        setStatus((oldtab) => tab.value);
    };

    const total = data?.data?.detailedResults[0]?.result?.totalElements ?? 0;
    const audiencesList = data?.data?.detailedResults[0]?.result?.content ?? [];

    const flatData: IAudiences[] = !isLoading && !error ? audiencesList?.flat() ?? [] : [];
    const columns = _.cloneDeep(audiencesColumns());
    const windowDialog = useOpenDialogWindow();
    const navigate = useNavigate();

    const alwaysOnCol: TDataRowModel = {
        field: "alwaysOn",
        headerName: "Always On",
        type: "string",
        cellClassName: "titleCell minCell",
        width: 90,
        maxWidth: 100,
        sortable: false,
        align: "center",
        code: "always_on",
        valueGetter: (cell: GridValueGetterParams<IAudiences>) => {
            if (hasAlwaysOnAvailable(cell.row)) {
                return (
                    <Button
                        title="Always On"
                        size="small"
                        color={hasAlwaysOnActive(cell.row) ? "success" : "primary"}
                        variant="contained"
                        onClick={() => openAlwaysOnAudience(cell.row.id)}
                    >
                        {hasAlwaysOnActive(cell.row) ? "Edit" : "Turn on"}{" "}
                    </Button>
                );
            }
            return "Not available";
        },
    };

    let cols: TDataColumnModel = {
        id: columns.id,
        createdDate: columns.createdDate,
        company: columns.company,
        creator: columns.creator,
        name: columns.name,
        status: columns.status,
        lastAudienceSize: columns.lastAudienceSize,
        CPM: columns.cpm,
    };

    if (["ACTIVATED", "ARCHIVED"].includes(status ?? "") || status === undefined) {
        cols = {...cols, alwaysOnCol};
    }

    cols = {...cols, actions: columns.actions};

    cols.id.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
        return (
            <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
                #{cell.row.id}
                <AppIcon
                    onClick={() => {
                        openTransparencyCard(cell.row.id, cell.row.audienceStatus);
                    }}
                    dataCy={"audienceTransparencyCardIcon"}
                    display={"inline"}
                    fontSize={"small"}
                    icon={"FileSearch"}
                    title={"Open Transparency card"}
                />
            </div>
        );
    };

    cols.creator.sortable = false;

    const hasAlwaysOnAvailable = (audience: IAudiences) => {
        let available = false;
        audience.audienceActivationRecap?.forEach((recap: any) => {
            if (recap?.canBeAlwaysOn) {
                available = true;
            }
        });
        return available;
    };

    const hasAlwaysOnActive = (audience: IAudiences) => {
        let active = false;
        audience.audienceActivationRecap?.forEach((recap: any) => {
            if (recap?.alwaysOnActivated) {
                active = true;
            }
        });
        return active;
    };

    cols.actions.minWidth = 160;
    cols.actions.valueGetter = (cell: GridValueGetterParams<IAudiences>) => {
        return (
            <div style={{display: "flex", gap: "10px"}}>
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                        handlePreview(cell.row.id);
                    }}
                >
                    Preview
                </Button>
                {["ARCHIVED", "ACTIVATED", "DRAFT"].includes(cell.row.audienceStatus) && (
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => {
                            handleEditView(cell.row.id, cell.row.audienceStatus);
                        }}
                    >
                        {cell.row.audienceStatus === "DRAFT" ? "Edit" : "View"}
                    </Button>
                )}
            </div>
        );
    };

    const audienceColumns = dataColumnModelUtils.renderModel(cols, "show");

    const handlePreview = (audienceId: number) => {
        const previewDialog: IOpenDialogAction = {
            dialogId: "AudiencePreviewPopup",
            component: AudiencePreviewPopup,
            componentProps: {
                audienceId: audienceId,
                previewMode: true,
                hideButtons: true,
                onDelete: () => {
                },
            },
            dialogProps: {
                className: "previewAudiencePopup",
            },
        };
        windowDialog.open(previewDialog).then();
    };

    const handleEditView = (audienceId: number, status: string) => {
        if (status === "DRAFT") {
            navigate(pageUtils.getPagePathById("audience-builder-update", {id: audienceId}));
        } else if (["ARCHIVED", "ACTIVATED"].includes(status)) {
            navigate(pageUtils.getPagePathById("audience-view", {audienceId: audienceId}));
            return;
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setPageConfig((curr) => {
            return {...curr, pageNumber};
        });
    };

    const handleSortModelChange = (params: GridSortModel) => {
        if (params[0].field !== sortModel[0].field || params[0].sort !== sortModel[0].sort) {
            setSortModel(params);
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPageConfig((curr) => {
            return {...curr, pageSize, pageNumber: 0};
        });
    };

    function setDrawerMode(mode: "transparency" | "channels" | "enablement") {
        if (mode === menuMode) {
            /*if (visibleDrawer) setMenuMode(undefined);
             setVisibleDrawer(!visibleDrawer);*/
        } else {
            setMenuMode(mode);
            setVisibleDrawer(true);
        }
    }

    function handleToggleDrawer(fact: any) {
        if (fact && !menuMode) setMenuMode("transparency");
        setVisibleDrawer(fact);
    }

    function openTransparencyCard(audienceId: number, audienceStatus: string) {
        if (!activeAudience || audienceId === activeAudience.id) {
            setVisibleDrawer(false);
            setActiveAudience({} as any);
        } else {
            setTcLoading(true);
            setVisibleDrawer(true);
            audiencesApi.byId(audienceId).then((res) => {
                setActiveAudience(res);
                setTcLoading(false);
            });
        }
    }

    const openAlwaysOnAudience = (audienceId: number) => {
        windowDialog.open({
            dialogId: "AudienceDealId",
            component: AlwaysOnDialog,
            componentProps: {
                audienceId: audienceId,
            },
            dialogProps: {
                className: "alwaysOnPopup",
            },
            onClose: () => {
                refetch();
            },
        });
    };

    const allAlwaysOnList = useAudiencesAlwaysOn();
    const alwaysOnCount = allAlwaysOnList?.data?.data?._embedded?.audiences?.length ?? 0;

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchString = event.target.value;
        if (searchString.length === 0) {
            resetSearch();
        }
        if (searchString && searchString.trim().length !== 0) {
            if (timerSearchField.current) {
                window.clearTimeout(timerSearchField.current);
            }

            timerSearchField.current = window.setTimeout(() => {
                setInputSearch(searchString);
            }, 800);
        } else {
            resetSearch();
        }
    };
    const resetSearch = () => {
        setInputSearch("");
    };
    const headerProperties = (
        <>
            <div style={{color: "#9DAABF"}}>Total audiences</div>
            <Box sx={{pt: 1, display: "flex"}}>
                <H4 data-cy={"total-audiences"}>{total}</H4>
                <span> </span>
            </Box>
        </>
    );

    const AudiencesHeaderButtons = () => {
        return (
            <>
                {alwaysOnCount > 0 && (
                    <Button variant="contained"
                            onClick={() => navigate(pageUtils.getPagePathById(PageId.audiencesAlwaysOn))}>
                        {alwaysOnCount} always on {alwaysOnCount > 1 ? " audiences" : " audience"}
                    </Button>
                )}
            </>
        );
    };

    let provStatus = visibleDrawer ? {right: "340px", top: "0.5rem"} : {right: 0, top: 0};
    const drawerTitle =
        menuMode === "channels"
            ? t("audience.cardTips.channelTipTitle")
            : menuMode === "enablement"
                ? t("audience.cardTips.enablementTipTitle")
                : t("audience.cardTips.transparencyTipTitle");
    const drawerTip =
        menuMode === "channels"
            ? t("audience.cardTips.channelTip")
            : menuMode === "enablement"
                ? t("audience.cardTips.enablementTip")
                : t("audience.cardTips.transparencyTip");

    return (
        <>
            <MainFrame
                frameId={"audience-admin-list"}
                breadCrumbProps={{noParent: false}}
                loading={isLoading}
                checkPageAccess={true}
                headerButtons={AudiencesHeaderButtons()}
                headerProps={{
                    headerProperties,
                }}
            >
                <AdminWrap>
                    {audienceManualStep > 0 && (
                        <AdminBanner>
                            <p style={{fontWeight: "900", fontSize: "1rem", marginBottom: "1rem"}}>
                                {audienceManualStep} {audienceManualStep > 1 ? " audiences are " : " audience is "} waiting
                                for a manual step
                            </p>
                            <p>
                                <Button variant="contained"
                                        onClick={() => navigate(pageUtils.getPagePathById(PageId.AudiencesManualStepList))}>
                                    View audience{audienceManualStep > 1 ? "s" : ""}
                                </Button>
                            </p>
                        </AdminBanner>
                    )}

                    <Content>
                        <AppTabs tabList={tabs} onClick={handleTabChange}>
                            <div style={{display: "flex", alignItems: "center", flexWrap: "nowrap", gap: "10px"}}>
                                <div style={{color: "#7587A3", width: "250px"}}>Audience name or ID</div>
                                <TextField
                                    inputRef={inputSearchField}
                                    autoFocus
                                    fullWidth={true}
                                    type={"search"}
                                    onChange={handleSearchChange}
                                    data-cy={"account-search"}
                                    InputProps={{
                                        startAdornment: (
                                            <div style={{marginLeft: "0.5rem"}}>
                                                <AppIcon display={"block"} color={"#00B5E2"} fontSize={"tiny"}
                                                         icon={"SearchIcon"}/>
                                            </div>
                                        ),
                                    }}
                                />
                            </div>
                        </AppTabs>

                        <GridTable
                            styleClass={"naked"}
                            height="100%"
                            data={{
                                columns: audienceColumns as any,
                                rows: flatData,
                            }}
                            noRowsText={"No Audiences found " + (inputSearch.length > 0 ? "for search term '" + inputSearch + "'" : "")}
                            pagerProps={{
                                onPageChange: handlePageChange,
                                onPageSizeChange: handlePageSizeChange,
                                activePage: pageConfig.pageNumber,
                                maxCount: total,
                                pageSize: pageConfig.pageSize,
                            }}
                            gridProps={{
                                onSortModelChange: handleSortModelChange,
                                disableSelectionOnClick: true,
                                autoHeight: false,
                                hideFooter: true,
                                columnBuffer: 30,
                                columnThreshold: 10,
                            }}
                        />

                        <FloatingMenuHandle hide={!visibleDrawer} position={provStatus}>
                            <div data-icon-button-zone className={"pad flex-v gap-tiny"}>
                                <button style={{width: "24px"}} data-cy={"btnActionToggleAudienceDrawer"}
                                        onClick={() => handleToggleDrawer(!visibleDrawer)}>
                                    <AppIcon
                                        themeColor={!visibleDrawer ? "primary" : "secondary"}
                                        fontSize={"small"}
                                        icon={"ChevronDownIcon"}
                                        rotate={visibleDrawer ? -90 : 90}
                                    />
                                </button>
                                <button data-cy={"btnActionOpenTransparencyCard"}
                                        onClick={() => setDrawerMode("transparency")}>
                                    <AppIcon themeColor={menuMode === "transparency" ? "primary" : "secondary"}
                                             fontSize={"small"} icon={"FileSearch"}/>
                                </button>
                                {["DRAFT"].includes(activeAudience.audienceStatus) && (
                                    <button style={{width: "24px"}} data-cy={"btnActionOpenChannels"}
                                            onClick={() => setDrawerMode("channels")}>
                                        <AppIcon themeColor={menuMode === "channels" ? "primary" : "secondary"}
                                                 icon={"ChannelsNav"}/>
                                    </button>
                                )}
                                {!["DRAFT"].includes(activeAudience.audienceStatus) && (
                                    <button style={{width: "24px"}} data-cy={"btnActionOpenEnablement"}
                                            onClick={() => setDrawerMode("enablement")}>
                                        <AppIcon themeColor={menuMode === "enablement" ? "primary" : "secondary"}
                                                 fontSize={"small"} icon={"Channels"}/>
                                    </button>
                                )}
                            </div>
                        </FloatingMenuHandle>
                        <AppDrawer
                            title={drawerTitle}
                            helperText={drawerTip}
                            helperTitle={drawerTitle}
                            open={visibleDrawer}
                            loading={tcLoading}
                            hideHandle={true}
                            actionButtonsVars={{...activeAudience, audienceId: activeAudience?.id}} // add audienceId
                            onClose={() => {
                                handleToggleDrawer(false);
                            }}
                        >
                            {menuMode === "channels" &&
                                <ProviderStatus audienceData={activeAudience} position={provStatus}
                                                isSegmentAudience={activeAudience?.audienceType?.code === "SEGMENTS"}/>}
                            {menuMode !== "channels" && <AudienceCard menuMode={menuMode} audience={activeAudience}/>}
                        </AppDrawer>
                    </Content>
                </AdminWrap>
            </MainFrame>
        </>
    );
};

const Content = styled("div")`
    padding: 1.5rem;
    height: 100%;
    max-width: 1800px;
    margin-inline: auto;
`;
