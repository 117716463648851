import {Typography} from "@mui/material";
import React from "react";
import {calculateCpmForSegment} from "../../../pages/SegmentBuilder/Segments.utils";
import Box from "@mui/material/Box";
import {DspTable, DspTableRow} from "../../../pages/Audiences/Enablement/components/DspSelectionStep";
import {useSegmentProvidersList} from "../../hooks/data/useSegmentProvidersList";
import {useSegmentsParameters} from "../../hooks/data/useSegmentsParameters";
import {moneyFormatter} from "../../../application/utils/formatters.utils";
import {useSegmentCountriesList} from "../../hooks/data/useSegmentCountriesList";
import {SegmentIdsDetails} from "../../../pages/SegmentBuilder/SegmentIdsDetails";
import {IExternalSegment} from "../../../application/entities/dataTypes/externalSegment";

export const SegmentsList = ({segments, accountId, currency, isExternalSegmentActivation}: {
    segments: IExternalSegment[],
    accountId: number,
    isExternalSegmentActivation?: boolean
    currency: string
}) => {


    const {segmentProvidersListData, segmentProvidersListLoading} = useSegmentProvidersList(accountId)
    const {segmentCountriesListData, segmentCountriesListLoading} = useSegmentCountriesList(accountId)
    const {segmentParamsData, segmentParamsLoading} = useSegmentsParameters(accountId)


    return <div>


        <Box px={2} mb={4} sx={{
            pt: 0,
            background: "#fff",
            borderRadius: "8px",
            border: "1px solid #ececec",

        }}>
            {!segmentCountriesListLoading && !segmentProvidersListLoading && !segmentParamsLoading && (
                <DspTable>
                    <DspTableRow gridTemplate={"1fr 120px 120px 120px 120px"} header={true}>
                        <Box>
                            <Typography sx={{
                                textAlign: 'left',
                                fontSize: "12px",
                                fontWeight: "bold"
                            }}>Segment Name</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{
                                textAlign: 'center',
                                fontSize: "12px",
                                fontWeight: "bold"
                            }}>CPM</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{
                                textAlign: 'center',
                                fontSize: "12px",
                                fontWeight: "bold"
                            }}>Ids Volume Max</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{
                                textAlign: 'center',
                                fontSize: "12px",
                                fontWeight: "bold"
                            }}>Country</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{
                                textAlign: 'center',
                                fontSize: "12px",
                                fontWeight: "bold"
                            }}>Provider</Typography>
                        </Box>
                    </DspTableRow>
                    {segments?.sort((a, b) => (a.segmentName ?? '').localeCompare((b.segmentName ?? ''))).map((segment: IExternalSegment) => {
                        const cpm = isExternalSegmentActivation ? (segment.cpmConverted ?? 0) : calculateCpmForSegment(segment.segmentCpm ?? 0, segmentParamsData.holdCoCpmMultiplier, segmentParamsData.agencyAdditionalCpm, segmentParamsData.currencyRate)

                        return <DspTableRow key={segment.id} gridTemplate={"1fr 120px 120px 120px 120px"}>

                            <Box sx={{display: "flex", flexDirection: 'column'}} py={0.5}>
                                <Typography variant="body1" fontWeight={900}
                                            title={segment.segmentName}>{segment.segmentName}</Typography>
                                <Typography variant="body2" color="#666"
                                            title={segment.segmentDescription}>{segment.segmentDescription}</Typography>
                            </Box>

                            <Box textAlign="center">
                                {moneyFormatter(cpm, {currency: currency})}
                            </Box>

                            <Box>
                                <SegmentIdsDetails segment={segment}/>
                            </Box>

                            <Box textAlign="center">
                                {!segmentCountriesListLoading && <>
                                    {segmentCountriesListData?.find((country: {
                                        label: string,
                                        value: string
                                    }) => country.value === segment.countryCode)?.label ?? segment.countryCode}
                                </>}
                            </Box>
                            <Box textAlign="center">
                                {!segmentProvidersListLoading && <>
                                    {segmentProvidersListData?.find((provider: {
                                        label: string,
                                        value: string
                                    }) => provider.value === segment.providerCode)?.label ?? segment.providerCode}
                                </>}
                            </Box>
                        </DspTableRow>
                    })}
                </DspTable>
            )}
        </Box>
    </div>
}