import {useSegmentBuilderStore} from "./SegmentBuilderStore";
import {Button, IconButton, Typography} from "@mui/material";
import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export const AudienceName = () => {
    const audienceName = useSegmentBuilderStore(st => st.audienceName)
    const updateAudienceName = useSegmentBuilderStore(st => st.updateAudienceName)
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [inputValue, setInputValue] = React.useState<string>(audienceName);

    const handleSave = () => {
        updateAudienceName(inputValue)
        setEditMode(!editMode)
    }
    const handleCancel = () => {
        setInputValue(audienceName)
        setEditMode(!editMode)
    }

    useEffect(() => {
        setInputValue(audienceName)
    }, [audienceName]);


    return <Box sx={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
        {!editMode && <>
            <Typography variant={'h4'} data-cy="page-title">{audienceName}</Typography>
            <IconButton aria-label="edit" onClick={() => setEditMode(!editMode)}>
                <EditOutlinedIcon sx={{height: "20px", width: "20px"}}/>
            </IconButton>

        </>}
        {editMode && <>
            <TextField
                sx={{width: "350px"}}
                inputProps={{
                    style: {
                        fontSize: "18px",
                        fontWeight: "bold",
                        lineHeight: "24px",
                        letterSpacing: "-0.2px",
                        fontFamily: "Nunito Sans"
                    },
                }}
                value={inputValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setInputValue(event.target.value);
                }}
            />
            <Box sx={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
                <Button variant="contained" onClick={() => handleSave()}>
                    Save
                </Button>
                <Button variant="outlined" onClick={() => handleCancel()}>
                    Cancel
                </Button>
            </Box>
        </>}
    </Box>
}