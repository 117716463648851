import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import {Badge, Box, Divider, Menu, styled, Typography} from "@mui/material";
import {AppIcon} from "components/ui/AppIcon";
import moment from "moment";
import React from "react";
import {useUiStore} from "redux/ui.store";
import {useFeatureFlag} from "stores/featureFlags.store";
import useLocalStorageState from "use-local-storage-state";

export const NewsFeed = () => {
    //Change localstorage id when update news to activate badge
    const [badgeVisible, setBadgeVisible] = useLocalStorageState<boolean>("evorra-news-20240412", true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [tabIndex, setTabIndex] = React.useState(0);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setBadgeVisible(false);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //const currentCompany = useUiStore(st => st.currentCompany)
    const currentBrand = useUiStore((st) => st.currentBrand);
    const accountMode = useUiStore((st) => st.accountMode);
    //const inBrand = currentBrand === undefined ? false : true
    // const companyName = currentCompany === undefined ? currentBrand?.parentAccountAssociations[0].parentAccount.companyName : currentCompany.companyName
    // const brandName = currentBrand === undefined ? null : currentBrand.companyName
    const featuresFlags = useFeatureFlag().featureFlags;


    // useEffect(()=>{
    //   if(inBrand){
    //     setTabIndex(1)
    //   } else{
    //     setTabIndex(0)
    //   }
    // },[inBrand])

    let notifications: any = [];

    //notification for data client account (exclude data provider)
    if (accountMode !== undefined) {
        if (featuresFlags?.dsp_facebook) {
            notifications.push(<FacebookJanuary25/>);
        }
        notifications.push(<EvorraDataSpin/>);

        if (featuresFlags?.dsp_jamloop) {
            notifications.push(<LiveRampNews/>);
        }
    }

    return (
        <>
            <div style={{alignSelf: "center", marginInline: "0.5rem"}} onClick={handleClick}>
                {badgeVisible && notifications.length > 0 ? (
                    <Badge badgeContent=" " variant="dot" color="error">
                        <AppIcon icon="Notification" style={{cursor: "pointer"}}/>
                    </Badge>
                ) : notifications.length > 0 ? (
                    <AppIcon icon="Notification" style={{cursor: "pointer"}}/>
                ) : (
                    <></>
                )}
            </div>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 12,
                    sx: {
                        background: "#fff",
                        paddingInline: "1rem",
                        paddingTop: "1rem",
                        width: "500px",
                        maxHeight: "calc(100vh - 150px)",
                        marginTop: "0.6rem",
                    },
                }}
            >
                <Typography variant="h3"
                            sx={{textTransform: "uppercase", fontSize: "18px", display: "flex", gap: "0.5rem"}}>
                    <AppIcon icon="Notification" color="#3B4559"/> Notifications
                </Typography>

                <Divider sx={{marginTop: "1rem"}}/>
                {/* {inBrand &&
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabIndex} onChange={(e, value)=>{e.preventDefault(); setTabIndex(value)}} aria-label="basic tabs example">
              <Tab label={companyName}  />
              <Tab label={brandName !== null ? brandName: 'Go to a brand to see notification'} disabled={brandName === null} />
              
            </Tabs>
          </Box>
          } */}

                {/* <Box role="tabpanel"  hidden={tabIndex !== 0} id={`company-notifications`}  aria-labelledby={`company-notifications`} >
          <NoNotification />
          </Box> */}

                {/* <Box role="tabpanel"  hidden={tabIndex !== 1} id={`brand-notifications`}  aria-labelledby={`brand-notifications`} >
          {brandNews?.length === 0 ? 
            <NoNotification />
            :
            brandNews.map((news, idx)=>{
              return <div key={idx}>
                {idx !== 0 && <Divider />}
                {news}
              </div>
            })
          }
          </Box>
          
          */}

                {notifications?.map((news: any, idx: number) => {
                    return (
                        <div key={idx}>
                            {idx !== 0 && <Divider/>}
                            {news}
                        </div>
                    );
                })}
            </Menu>
        </>
    );
};
const FacebookJanuary25 = () => {
    return (
        <NewsContainer>
            <NewsTitle>Use Evorra Audiences Directly on Facebook

            </NewsTitle>
            <NewsDate>{moment("2025-01-14").format("MMMM Do YYYY")}</NewsDate>
            <NewsText>
                Previously, due to Facebook restrictions, the enablement of Evorra data on this activation channel was
                not allowed. The great news is that this has now changed as Facebook has changed it’s terms and
                conditions on the use of 3rd Party Data on its platform.
            </NewsText>
            <NewsText>
                You will now be able to build and activate Evorra audiences on Facebook as long as:
            </NewsText>
            <NewsText>
                a. You have connected a valid Facebook Business Manage account to one of the brands you have previously
                set-up on Evorra
            </NewsText>
            <NewsText>
                b. The audience you have built has enough identifiers in it which work on Facebook
            </NewsText>
            <NewsText>Kindly reach out to your Evorra representative with any questions.</NewsText>
        </NewsContainer>
    );
};

const EvorraDataSpin = () => {
    return (
        <NewsContainer>
            <NewsTitle>Evorra Data Spine LIVE</NewsTitle>
            <NewsDate>{moment("2024-04-12").format("MMMM Do YYYY")}</NewsDate>
            <NewsText>
                Evorra has updated and activated its enhanced data spine across all data parters on the platform. This
                has resulted in a more granular and
                precise volume of unique profiles being shown in the interface, with each profile having an enhanced
                volume of attributes associated with it.
                Immediate impacts and benefits of this enhancement include:
            </NewsText>
            <NewsText>
                a. Unique profiles in the interface have now resolved to just under 500 million globally with each
                profile increasing both in the attributes
                attributed and addressable IDs attached to it for enhanced richness and targeting outcomes
            </NewsText>
            <NewsText>
                b. Unique profile counts will increase steadily in the weeks and months ahead as more data partners and
                addressable ID partners are activated
                and be able to utilise Evorra’s global ID spine
            </NewsText>
            <NewsText>Kindly reach out to your Evorra representative with any questions.</NewsText>
        </NewsContainer>
    );
};


const MetaSuspended = () => {
    return (
        <NewsContainer>
            <NewsTitle>META Connection has been suspended</NewsTitle>
            <NewsDate>{moment("2023-11-14").format("MMMM Do YYYY")}</NewsDate>
            <NewsText>
                The API connection to Meta has been deactivated across all accounts on Evorra. Given increasingly
                stringent privacy requirements relating to
                the use of data on Meta and the recent ruling by the European Data Protection Board (EDPB) specifically
                in relation to Meta, the ability to
                send audiences to this platform is no longer available. This does not affect any other connections
                active in the Evorra interface.
            </NewsText>
            <NewsText>
                With the launch of Client Clean Rooms at the end of H1 2024 by Evorra, this functionality will be
                reinstated. The Evorra team apologies for
                any inconvenience this may cause. Please reach out to your Evorra representative for any further
                clarification.
            </NewsText>
        </NewsContainer>
    );
};

const LiveRampNews = () => {
    return (
        <NewsContainer>
            <NewsTitle>LiveRamp is now available</NewsTitle>
            <NewsDate>{moment("2023-10-30").format("MMMM Do YYYY")}</NewsDate>
            <NewsText>
                LiveRamp is now available as an additional channel to enable audiences. This is for <u>US audiences
                only</u>. If you build a segment with US
                and non-US audiences and enable via LiveRamp, only the US IDs will be sent.
                <br/>
            </NewsText>
        </NewsContainer>
    );
};

const NoNotification = () => {
    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{textAlign: "center", paddingBlock: "2rem"}}>
                <NotificationsOffOutlinedIcon sx={{fontSize: "72px", color: "#7587A3"}}/>
                <Typography sx={{color: "#7587A3", fontSize: "18px", fontWeight: "bold"}}>
                    No notifications yet <br/>
                </Typography>
            </div>
        </div>
    );
};

const NewsContainer = styled(Box)({
    paddingBlock: "1.5rem",
});
const NewsTitle = styled(Typography)({
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "22px",
    letterSpacing: "-0.2px",
});
const NewsDate = styled(Typography)({
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "1.5",
    letterSpacing: "0",
    color: "#7587A3",
});

const NewsText = styled(Typography)({
    fontSize: "14px",
    lineHeight: "1.5",
    marginBlock: "0.5rem",
});
