import {objectApi} from "../../../application/entities/dataApi";
import {useQuery} from "react-query";
import {BackendError} from "../../../types/backendError";

export const useSegmentProvidersList = (accountId: number) => {
    const accountsApi = new objectApi.accounts();

    const {
        isLoading,
        error,
        data,
        refetch,
        remove
    } = useQuery<any, BackendError>(['segment-providers-list', `account-${accountId}`, 'accounts'], () => {
        return accountsApi.getSegmentsProviders(+accountId).then((providers) => {
            return providers.data.providers.map((provider: any) => {
                return {
                    value: provider.providerCode,
                    label: provider.providerName
                }
            });


        })
    }, {
        cacheTime: 30 * 60 * 1000, //30 minutes
        enabled: Boolean(accountId),
    });

    return {
        segmentProvidersListLoading: isLoading,
        segmentProvidersListError: error,
        segmentProvidersListData: data,
        segmentProvidersListRefetch: refetch,
        segmentProvidersListRemove: remove
    }
}