import React from 'react';
import styledOld from 'styled-components/macro';
import {
  Columns,
  ColumnsMiddle,
  GridItem,
  GridItemFull,
  GridItemFullAuto,
  StackerFullHeight
} from '../../ui/AppElements';
import {pageUtils} from '../../../application/pages/pages.utils';
import {AppIcon} from '../../ui/AppIcon';
import {Box, CircularProgress, Typography} from '@mui/material';
import {IAppPage} from '../../../application/pages/pages.utils.types.tmp';
import {styled} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export interface IMainFrameHeaderProps {
    frameId: any;
    frameInfo?: Partial<IAppPage>;
    headerContent?: any;      // Component, left part of the header
    headerProperties?: any;   // Component, right part of the header
    headerButtons?: any;
    headerTitle?: any;
    headerDescription?: any;
    hideHeaderDefault?: boolean;
    hideHeaderIcon?: boolean;
    children?: any;
    isLoading?: boolean;
    icon?: any;
    overrideTitleComponent?: React.ReactNode;
}

export const MainFrameHeader = (props: IMainFrameHeaderProps) => {

    const page = {...pageUtils.getPageById(props.frameId), ...props.frameInfo};
    const {isLoading, headerContent, headerDescription, overrideTitleComponent} = props;

    const icon = isLoading ? <CircularProgress size={16}/> : page.icon ?
        <AppIcon chip={true} fontSize={'default'} display={'inline'} color={'#3B4559'} icon={page.icon}/> : null
    return (
        <Container>
            <GridItem style={{marginLeft: '2em'}}>
                {overrideTitleComponent ? <Columns style={{gap: '20px'}}>
                        <Box sx={{pt: 2, textAlign: 'center', width: 32}}>
                            {!isLoading && icon}
                        </Box>
                        <div className={'flex-h flex-align-middle'} style={{paddingTop: "5px"}}>
                            {overrideTitleComponent}
                        </div>
                    </Columns> :
                    <Columns style={{gap: '20px'}}>
                        {!props.hideHeaderIcon && <Box sx={{pt: 2, textAlign: 'center', width: 32}}>
                            {!isLoading && icon}
                        </Box>}
                        <div className={'flex-h flex-align-middle'}>
                            {(!props.hideHeaderDefault) &&
                                <Box sx={{py: 2, mr: 2}}>
                                    {props.headerTitle ? props.headerTitle :
                                        <Typography variant={'h4'} data-cy="page-title">{page.title}</Typography>}
                                    <PageDescription>
                                        {(page.description1 || Boolean(headerDescription)) &&
                                            <p>{headerDescription ?? page.description1}</p>}
                                        {page.description2 && <p>{page.description2}</p>}
                                    </PageDescription>
                                </Box>}
                            {headerContent}
                            {props.headerProperties &&
                                <Box sx={{borderLeft: '1px solid #ededed', px: 2}}>{props.headerProperties}</Box>}
                        </div>
                    </Columns>
                }
            </GridItem>
            <GridItemFullAuto>
                {props.children ?? ''}
            </GridItemFullAuto>
            <StackerFullHeight>
                <GridItemFull>
                </GridItemFull>
                {props.headerButtons && <Box sx={{py: 2.5, px: 2}}>
                    {props.headerButtons ?? ''}
                </Box>}
            </StackerFullHeight>
        </Container>
    );
};

const Container = styled(ColumnsMiddle)((props: { theme: Theme }) => ({
    background: props.theme.palette.background.default,
    borderBottom: '1px solid ' + props.theme.skin.borderColor,
}));


const PageDescription = styledOld.div`
  color: #7587A3;
  font-size: 12px;
`;
