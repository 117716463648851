import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Collapse,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {objectApi} from "application/entities/dataApi";
import {idList} from "application/utils/fpd.utils";
import {useFirstPartyData} from "components/hooks/useFirstPartyData";
import {useAppToast} from "components/live/AppToast";
import {InputFieldLabel} from "components/templates/InfoBlock";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {uploadFile} from "../../../lib/aws-sdk";
import {pageUtils} from "../../../application/pages/pages.utils";
import {PageId} from "../../../application/pages/pages.config";

export const UploadParquetCsvZip = () => {
    const {accountId} = useParams<{ accountId: string }>() as { accountId: string };
    const navigate = useNavigate();

    const accountApi = new objectApi.accounts();
    const {data: existingFPDFiles, isLoading, error, refetch} = useFirstPartyData(+accountId);
    const appToast = useAppToast();

    const existingFPD = existingFPDFiles?.map((fpd) => fpd.name?.toLowerCase());

    const [fileName, setFileName] = useState<string>("");
    const [fileNameError, setFileNameError] = useState<string | null>(null);
    const [fileDescription, setFileDescription] = useState<string>("");
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState(false);

    const [file, setFile] = useState<any>(null);

    const [isExampleShown, setIsExampleShown] = useState(false);


    useEffect(() => {
        if (fileName.length > 3 && !existingFPD?.includes(fileName.toLowerCase()) && file !== null) {
            setIsBtnDisabled(false);
            setFileNameError(null);
        } else {
            if (fileName.length < 3 && fileName.length > 0) {
                setFileNameError("This field must contain at least 3 characters");
            }
            if (fileName.length > 3 && existingFPD?.includes(fileName.toLowerCase())) {
                setFileNameError("This file name already exists");
            }
            setIsBtnDisabled(true);
        }
    }, [fileName, existingFPD]);


    const getFileExtension = (fileName: string) => {

        if (file.name.includes('.parquet'))
            return '.parquet'

        if (file.name.includes('.csv.zip'))
            return '.csv.zip'

        if (file.name.includes('.csv.gz'))
            return '.csv.gz'

        if (file.name.includes('.tsv.zip'))
            return '.tsv.zip'

        if (file.name.includes('.tsv.gz'))
            return '.tsv.gz'

        if (file.name.includes('.csv'))
            return '.csv'

        if (file.name.includes('.tsv'))
            return '.tsv'

        return ''
    }


    const handleSubmit = () => {
        setIsUploading(true)
        let data = new FormData();
        file && data.append("file", file);


        const extension = getFileExtension(file.name)

        if (extension === '') {
            appToast.open({
                toastId: "globalToast",
                severity: "error",
                message: "Error while uploading First Party Data. Reason: File extension not supported",
            });
            setIsUploading(false)
            return
        }

        //step 1 Get the filename from the backend
        accountApi.generateFirstPartyDataFileCode({
            accountId: +accountId,
            fileName: fileName,
            description: fileDescription,
        }).then((res) => {

            const fileCode = res.data.fileCode as string
            const bucketName = res.data.s3Bucket as string
            if (!fileCode || !bucketName) {
                appToast.open({
                    toastId: "globalToast",
                    severity: "error",
                    message: "Error while uploading First Party Data, please try later. Reason: Backend fileCode or bucket empty",
                });
                throw new Error('No file code returned from the backend')
            }
            //step 2 upload the file to s3
            const fileNameToUpload = fileCode + extension
            uploadFile(+accountId, file, fileNameToUpload, bucketName).then((res) => {
                setIsUploading(false)
                appToast.open({
                    toastId: "globalToast",
                    severity: "success",
                    message: "Your First Party Data has been uploaded",
                });
                refetch();
                navigate(pageUtils.getPagePathById(PageId.myDatas, {accountId: accountId}));
            }).catch((e) => {
                setIsUploading(false)
                appToast.open({
                    toastId: "globalToast",
                    severity: "error",
                    message: "Error while uploading First Party Data, please try later. Reason: Upload Fails",
                });
                console.log('Error when uploading the file to s3', e)
                accountApi.deleteFirstPartyData(+accountId, fileCode).then((res) => {
                    console.log('file removed')
                })
            })


        }).catch((e) => {
            setIsUploading(false)
            appToast.open({
                toastId: "globalToast",
                severity: "error",
                message: "Error while uploading First Party Data, please try later. Reason: Backend fileCode fails",
            });
            console.log('Error when getting the fileName', e)
        })

    };


    if (isUploading) {
        return (
            <Box sx={{textAlign: "center"}} mt={4}>
                <CircularProgress size={24}/>
                <Typography mt={2}>Upload in progress, please wait...</Typography>
            </Box>
        );
    }


    return (
        <Box mt={3}>
            <Box mb={3} sx={{background: "#fcfcfc", padding: "1rem", borderRadius: "7px", border: "1px solid #ececec"}}>
                <Typography mb={1} sx={{fontWeight: 900, fontSize: "15px"}}>
                    File requirements:
                </Typography>

                <Typography pl={1}>- Accepted extensions: .csv, .parquet, .csv.zip, .csv.gz, .tsv.zip and
                    .tsv.gz</Typography>
                <Typography pl={1}>
                    - Columns Accepted:
                    {idList.map((idItem) => {
                        return <Chip sx={{height: "24px", marginInline: "3px"}} size="small" key={idItem.value}
                                     label={idItem.value}/>;
                    })}

                </Typography>
                <Typography pl={2} sx={{fontWeight: "bold", fontStyle: "italic"}}>
                    The "postcode" column is only accepted for UK data
                </Typography>
                <Typography pl={1}>- Maximum size: 4GB</Typography>
                <Typography pl={1}>- In each column you must populate each row with a unique ID, corresponding to the
                    name in the first row</Typography>
                <Typography pl={1}>- If you have multiple ID types for a given row, ensure they are populated in the
                    correct columns</Typography>
                <Typography pl={1}>- You can download a <a href="/uploadFirstPartyDataTemplate.csv"
                                                           target="_blank">file
                    template</a> or <span style={{cursor: "pointer", color: "#00B5E2"}}
                                          onClick={() => setIsExampleShown(!isExampleShown)}>
                    {isExampleShown ? "hide the File Example" : "show a File Example"}
                </span></Typography>


                <Collapse in={isExampleShown}>
                    <TableContainer component={Box} sx={{backgroundColor: "#fafafa", mt: 1}}>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{lineHeight: "1rem"}}>email</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>hashed_email_sha256</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>uid2</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>ios_id</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>rampid</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                    <TableCell sx={{lineHeight: "1rem"}}>sample@gmail.com</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}></TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>yeri7624bhkjdfhk</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}></TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>89764djrghbjdghf</TableCell>
                                </TableRow>
                                <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                    <TableCell sx={{lineHeight: "1rem"}}></TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>#########</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}></TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>o82347rtbgjkcfg</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}></TableCell>
                                </TableRow>
                                <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                    <TableCell sx={{lineHeight: "1rem"}}>xyz@email.com</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}></TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>9587pbdvshfljkd</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}></TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}></TableCell>
                                </TableRow>
                                <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                    <TableCell sx={{lineHeight: "1rem"}}>...</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>...</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>...</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>...</TableCell>
                                    <TableCell sx={{lineHeight: "1rem"}}>...</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Collapse>
            </Box>

            <Box sx={{display: "block", margin: "auto", width: "280px"}}>
                <InputFieldLabel>
                    <Input
                        data-cy={"upload-list"}
                        id="import-button"
                        inputProps={{
                            accept: ".csv.zip, .parquet, .csv.gz, .tsv.zip, .tsv.gz, .csv",
                        }}
                        onChange={(e: any) => {
                            setFile(e.currentTarget.files[0]);
                            setFileName(e.currentTarget.files[0]?.name ?? "");
                        }}
                        type="file"
                        sx={{display: "none"}}
                    />
                    <Box
                        sx={{
                            margin: "auto",
                            marginBottom: "1rem",
                            cursor: "pointer",
                            height: "30px",
                            width: "fit-content",
                            background: "linear-gradient(94.21deg, #00b5e2 0%, #10caf9 100%)",
                            display: "flex",
                            alignItems: "center",
                            padding: "0 16px",
                            borderRadius: "20px",
                            color: "white",
                            fontSize: "12px",
                            fontWeight: "normal",
                        }}
                    >
                        Select a file
                    </Box>
                </InputFieldLabel>

                <Box mb={2}>
                    <Typography mb={1}>File Name *</Typography>
                    <TextField
                        id={"fileName"}
                        value={fileName}
                        sx={{width: "280px"}}
                        onChange={(e) => {
                            setFileName(e.target.value);
                        }}
                        error={fileNameError !== null}
                        helperText={fileNameError}
                    />
                </Box>
                <Box mb={2}>
                    <Typography mb={1}>File Description</Typography>
                    <TextField
                        id={"fileDescription"}
                        value={fileDescription}
                        onChange={(e) => {
                            setFileDescription(e.target.value);
                        }}
                        multiline
                        maxRows={4}
                        sx={{width: "280px"}}
                        inputProps={{
                            style: {
                                height: "70px",
                                padding: "10px 10px !important",
                            },
                        }}
                    />
                </Box>
            </Box>
            <Box sx={{textAlign: "center"}}>
                <Button variant="contained" disabled={isBtnDisabled} onClick={handleSubmit}>
                    Submit
                </Button>
            </Box>
        </Box>
    );
};
