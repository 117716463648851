import {PageId} from "application/pages/pages.config";
import {EPermission} from "application/utils/granter.utils";
import i18n from "i18n.config";
import {IActionButtonsList} from "../../components/controls/ActionButtons";
import {accessList} from "../access/access";
import {IAccounts} from "../entities/dataTypes/accounts";
import {IAudiences} from "../entities/dataTypes/audiences";
import {AUTHUser} from "./../utils/AuthUser";

const actions: IActionButtonsList = {} as const;

actions.dashboard = {
    create: {
        title: i18n.t("account.add"),
        variant: "contained",
        pageId: PageId.accountCreate,
        accesses: accessList.accounts.create,
    },
};

actions.accountsList = {
    create: {
        title: i18n.t("account.add"),
        variant: "contained",
        pageId: PageId.accountCreate,
        accesses: accessList.accounts.create,
        code: "add-account-btn",
    },
    download: {
        title: i18n.t("labels.downloadAll"),
        variant: "outlined",
        accesses: accessList.accounts.download,
        action: (audienceData: IAccounts) => {
        },
        code: "download-account-list-btn",
    },
};

actions.accounts = {
    view: {
        title: "View",
        variant: "outlined",
        pageId: PageId.adminAccountView,
    },
    edit: {
        title: i18n.t("account.edit"),
        variant: "outlined",
        pageId: PageId.accountEdit,
    },
    userManagement: {
        title: i18n.t("account.userManagement"),
        variant: "outlined",
        pageId: PageId.companyUserList,
        accesses: accessList.companyUsers.list,
        condition: (account: IAccounts) => account?.accountType === "COMPANY" || account?.accountType === null,
    },
    DspAccountChannelParameters: {
        title: i18n.t("dsp.enablementSetup"),
        variant: "outlined",
        pageId: PageId.channelsSetup,
        accesses: accessList.activation.setup,
        condition: (account: IAccounts) => account?.accountType === "BRAND" && account?.dataProvider !== false,
    },
    VaultSetup: {
        title: i18n.t("vaultSettingsPageLabels.dataVaultSetup"),
        variant: "outlined",
        pageId: PageId.vaultSetup,
        accesses: accessList.dataVault.setup,
        condition: (account: IAccounts) => account?.accountType === null,
    },
    goToHome: {
        title: "Go to Home",
        variant: "outlined",
        pageId: PageId.companyDashboard,
        accesses: accessList.accounts.create,
    },
};

actions.account = {
    create: {
        title: i18n.t("account.add"),
        variant: "contained",
        pageId: PageId.accountCreate,
        accesses: accessList.accounts.create,
    },
    userManagement: {
        title: i18n.t("account.userManagement"),
        variant: "outlined",
        pageId: PageId.companyUserList,
        accesses: accessList.companyUsers.list,
        condition: ({accountId, account}) => account?.accountType === "COMPANY" || account?.accountType === null,
    },
    dataVaultPermissions: {
        title: `${i18n.t("dataVault.dataVault")} ${i18n.t("dataVault.permissions")}`,
        variant: "outlined",
        pageId: PageId.vaultPermissions,
        accesses: accessList.dataVault.permissions,
        code: "vault-permission-btn",
        condition: ({accountId, account}) => account?.dataProvider === true,
    },
    dataVaultSetup: {
        title: `${i18n.t("dataVault.dataVault")} ${i18n.t("labels.setup")}`,
        variant: "outlined",
        pageId: PageId.vaultSetup, // we are not supposed to use pageId and accesses at same time
        accesses: accessList.dataVault.setup,
        code: "vault-setup-btn",
        condition: ({accountId, account}) => account?.dataProvider === true,
    },
    billings: {
        title: i18n.t("dataVault.viewBillings"),
        variant: "outlined",
        actionVars: ["accountId"],
        accesses: accessList.accounts.billings,
    },
    suspend: {
        title: i18n.t("account.suspend"),
        variant: "outlined",
        actionVars: ["accountId"],
        accesses: accessList.accounts.suspend,
    },
};

actions.dataVault = {
    suspend: {
        title: (data: { account: IAccounts; accountId: number }) => {
            return data.account.activated ? i18n.t("account.suspend") : i18n.t("account.activate");
        },
        variant: "contained",
        actionVars: ["accountId"],
        accesses: accessList.accounts.suspend,
        tip: (data: { account: IAccounts; accountId: number; overDisabled?: boolean }) => {
            return data?.account?.activated
                ? "You are monetizing your data.  To turn off your data from the Evorra marketplace, please contact your Evorra representative or support@evorra.com."
                : undefined;
        },
        conditionDisable: (data: { account: IAccounts; accountId: number; overDisabled?: boolean }) => {
            return data?.overDisabled !== undefined ? !data?.overDisabled : data?.account?.activated;
        },
    },
};

actions.report = {
    globalReport: {
        title: i18n.t("globalCockpit.globalReports"),
        variant: "contained",
        pageId: PageId.reports,
        accesses: accessList.cockpit.report,
    },
};

actions.reports = {
    accountReport: {
        title: i18n.t("globalCockpit.reports.accountReport"),
        variant: "contained",
        accesses: accessList.cockpit.report,
    },
    audienceReport: {
        title: i18n.t("globalCockpit.reports.audienceReport"),
        variant: "contained",
        accesses: accessList.cockpit.report,
    },
    paymentReport: {
        title: i18n.t("globalCockpit.reports.paymentReport"),
        variant: "contained",
        accesses: accessList.cockpit.report,
    },
};

actions.reportsLinks = {
    globalCockpit: {
        title: i18n.t("globalCockpit.reportsLinks.globalCockpit"),
        variant: "contained",
        pageId: PageId.globalCockpit,
        accesses: accessList.cockpit.report,
    },
    businessCockpit: {
        title: i18n.t("globalCockpit.reportsLinks.businessCockpit"),
        variant: "contained",
        pageId: PageId.businessCockpit,
        accesses: accessList.cockpit.report,
    },

};

actions.mylists = {
    uploadList: {
        title: i18n.t("uploadLists.uploadExclusionList"),
        variant: "contained",
        accesses: accessList.prePackagedList.upload,
    },
};

actions.mylistsCard = {
    deleteList: {
        title: i18n.t("labels.delete"),
        variant: "contained",
        accesses: accessList.prePackagedList.delete,
        actionVars: ["key"],
    },
};

actions.audience = {
    view: {
        title: "View",
        icon: "Eye",
        variant: "outlined",
        pageId: PageId.audienceView,
        condition: (audienceData: IAudiences) => ["ARCHIVED", "ACTIVATED"].includes(audienceData.audienceStatus),
    },
    edit: {
        title: (audienceData: IAudiences) => (["ARCHIVED", "ARCHIVED"].includes(audienceData.audienceStatus) ? "View" : "Edit"),
        icon: "Edit",
        variant: "outlined",
        pageId: (audienceData: IAudiences) => audienceData.audienceType?.code === "SEGMENTS" ? PageId.segmentBuilder : PageId.audienceBuilderForm,
        condition: (audienceData: IAudiences) => audienceData.audienceStatus === "DRAFT",
    },
    review: {
        title: i18n.t("account.review"),
        icon: "Audience",
        variant: "outlined",
        accesses: accessList.audiences.approval,
        condition: (audienceData: IAudiences) => audienceData.audienceStatus === "PENDING_APPROVAL",
        action: () => {
        },
    },
    cancel: {
        title: i18n.t("audience.cancelRequest"),
        icon: "Close",
        variant: "outlined",
        accesses: accessList.audiences.create,
        condition: (audienceData: IAudiences) => audienceData.audienceStatus === "PENDING_APPROVAL" && audienceData.requester.id === AUTHUser.userId,
        action: () => {
        },
    },
    pay: {
        title: i18n.t("account.payAudience"),
        icon: "Audience",
        variant: "outlined",
        accesses: accessList.audiences.create,
        condition: (audienceData: IAudiences) => audienceData.audienceStatus === "APPROVED",
        action: () => {
        },
    },
    activate: {
        title: i18n.t("account.enable"),
        icon: "Audience",
        variant: "outlined",
        pageId: PageId.audienceEnablement,
        condition: (audienceData: IAudiences) => audienceData.audienceStatus === "DRAFT",
        conditionDisable: (audienceData: IAudiences) =>
            ["ARCHIVED", "ACTIVATED"].includes(audienceData.audienceStatus) ||
            !audienceData.dspCampaignInfo
                ?.map((a) => a?.dspItems)
                ?.map((item) => item?.map((element) => element?.maxSize > element?.minSize).reduce((prev, next) => prev || next, false))
                .reduce((prev, next) => prev || next, false),
    },
    copy: {
        title: i18n.t("account.duplicate"),
        variant: "outlined",
        icon: "Duplicate",
        accesses: accessList.audiences.copy,
        condition: (audienceData: IAudiences) => audienceData.audienceType?.code !== "FIRST_PARTY",
        action: () => {
        },
    },
    copyToBrand: {
        title: "Copy to another Brand",
        icon: "Duplicate",
        variant: "outlined",
        accesses: accessList.audiences.copy,
        action: () => {
        },
        condition: (audienceData: IAudiences) => {
            if (audienceData.audienceType?.code === "FIRST_PARTY") {
                return false;
            }
            const brandWithPermissions = AUTHUser.companyBrand?.filter((brand) => {
                if (brand.permission) {
                    return [EPermission.BRANDADMIN, EPermission.BRANDCREATOR].includes(brand.permission);
                } else {
                    return false;
                }
            });
            if (brandWithPermissions.length > 1) {
                return true;
            }
            return false;
        },
    },
    archive: {
        title: i18n.t("account.archive"),
        icon: "Archive",
        variant: "outlined",
        accesses: accessList.audiences.archive,
        condition: (audienceData: IAudiences) => audienceData.audienceStatus === "ACTIVATED",
        action: () => {
        },
    },
    trash: {
        title: i18n.t("account.delete"),
        icon: "Trash",
        variant: "outlined",
        accesses: accessList.audiences.trash,
        condition: (audienceData: IAudiences) => {
            if (audienceData.audienceStatus === "DRAFT") {
                return true;
            } else if (
                (audienceData.audienceStatus === "APPROVED" && AUTHUser.getPermissionForBrandId(audienceData?.account?.id) === EPermission.BRANDADMIN) ||
                (audienceData.audienceStatus === "APPROVED" && audienceData?.author?.id === AUTHUser.userId)
            ) {
                return true;
            } else {
                return false;
            }
        },
        action: () => {
        },
    },
};

actions.globalAccount = {
    edit: {
        title: (audienceData: IAudiences) => (["ARCHIVED", "ARCHIVED"].includes(audienceData.audienceStatus) ? "View" : "Edit"),
        icon: "Edit",
        variant: "outlined",
        pageId: PageId.audienceBuilderForm,
        condition: (audienceData: IAudiences) => audienceData.audienceStatus !== "ACTIVATED",
    },
    activate: {
        title: i18n.t("account.enable"),
        icon: "Audience",
        variant: "outlined",
        pageId: PageId.audienceActivation,
        conditionDisable: (audienceData: IAudiences) =>
            ["ARCHIVED", "ACTIVATED"].includes(audienceData.audienceStatus) ||
            !audienceData.dspCampaignInfo
                ?.map((a) => a?.dspItems)
                ?.map((item) => item?.map((element) => element?.maxSize > element?.minSize).reduce((prev, next) => prev || next, false))
                .reduce((prev, next) => prev || next, false),
    },
    copy: {
        title: i18n.t("account.duplicate"),
        variant: "outlined",
        icon: "Duplicate",
        accesses: accessList.audiences.copy,
        action: () => {
        },
    },
    archive: {
        title: i18n.t("account.archive"),
        icon: "Archive",
        variant: "outlined",
        accesses: accessList.audiences.archive,
        condition: (audienceData: IAudiences) => !["ARCHIVED", "DRAFT"].includes(audienceData.audienceStatus),
        action: () => {
        },
    },
    trash: {
        title: i18n.t("account.delete"),
        icon: "Trash",
        variant: "outlined",
        accesses: accessList.audiences.trash,
        condition: (audienceData: IAudiences) => audienceData.audienceStatus === "DRAFT",
        action: () => {
        },
    },
};

export {actions};
