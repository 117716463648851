import {objectApi} from "../../../application/entities/dataApi";
import {useQuery} from "react-query";
import {BackendError} from "../../../types/backendError";

export const useSegmentsParameters = (accountId: number) => {
    const accountsApi = new objectApi.accounts();

    const {
        isLoading,
        error,
        data,
        refetch,
        remove
    } = useQuery<any, BackendError>(['segment-parameters', `segment-parameters-${accountId}`, `account-${accountId}`, 'accounts'], () => {
        return accountsApi.getSegmentsParameters(+accountId).then((params) => {
            const currencyRate = params.data.currencyConversionRate ?? 1
            const agencyAdditionalCpm = params.data.agencyAdditionalCpm ?? 0
            const holdCoCpmMultiplier = params.data.holdCoCpmMultiplier ?? 1
            return {
                currencyRate,
                agencyAdditionalCpm,
                holdCoCpmMultiplier
            }


        })
    }, {
        cacheTime: 30 * 60 * 1000, //30 minutes
        enabled: Boolean(accountId),
    });

    return {
        segmentParamsLoading: isLoading,
        segmentParamsError: error,
        segmentParamsData: data,
        segmentParamsRefetch: refetch,
        segmentParamsRemove: remove
    }
}