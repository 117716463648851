import {IAudiences} from "../application/entities/dataTypes/audiences";
import {profilesFormatter} from "../_configuration/formaters";
import {IExternalSegmentActivation} from "../application/entities/dataTypes/audienceActivationRecap";
import {IdCodeType} from "../pages/SegmentBuilder/Segments.utils";


/*
    Extract the number of profiles from the audience
    @param audience
 */
export function getNbProfiles(audience: IAudiences) {
    const uniqueProfiles =
        audience.lastAudienceSize === -1 || audience.lastAudienceSize === undefined
            ? "< Minimum"
            : `${profilesFormatter.format(audience.lastAudienceSize)} profiles`;
    const deterministicIds =
        audience.lastAuthenticatedAudienceSize === -1 || audience.lastAuthenticatedAudienceSize === undefined
            ? "< Minimum"
            : `${profilesFormatter.format(audience.lastAuthenticatedAudienceSize)}`;
    const universalIds =
        audience.lastUniversalIdAudienceSize === -1 || audience.lastUniversalIdAudienceSize === undefined
            ? "< Minimum"
            : `${profilesFormatter.format(audience.lastUniversalIdAudienceSize)}`;
    return {uniqueProfiles, deterministicIds, universalIds}
}


/*
Extract the country code from the externalSegmentActivations for a segment audience
@param audience
 */
export function getGeoForSegmentAudienceFromAudienceActivationRecap(audience: IAudiences) {
    const externalSegmentActivations = audience?.audienceActivationRecap?.map(recap => {
        return recap?.externalSegmentActivations
    }).flat()

    const segmentGeoListWithDuplicate = externalSegmentActivations?.reduce((acc: string[], externalSegment) => {
        const code = externalSegment?.countryCode
        if (code) {
            acc.push(code)
        }
        return acc
    }, [])

    return [...new Set(segmentGeoListWithDuplicate)]
}

/*
Extract the country code from the audienceFeatures for a segment audience
@param audience
 */
export function getGeoForSegmentAudienceFromAudienceFeatures(audience: IAudiences) {
    const segmentsList = audience?.audienceFeatures?.map(feature => {
        if (!feature?.featureValues) return []

        return feature?.featureValues.map(featureValue => {
            return JSON.parse(featureValue)
        })
    }).flat()

    const segmentGeoListWithDuplicate = segmentsList?.reduce((acc: string[], externalSegment) => {
        const code = externalSegment?.countryCode
        if (code) {
            acc.push(code)
        }
        return acc
    }, [])

    return [...new Set(segmentGeoListWithDuplicate)]
}

/*
Extract the provider list from the externalSegmentActivations for a segment audience
@param audience
@param providerList from useSegmentProvidersList
 */
export function getProvidersForSegmentAudienceFromAudienceActivationRecap(audience: IAudiences, providerList: Array<{
    value: string,
    label: string
}>) {
    const externalSegmentActivations = audience?.audienceActivationRecap?.map(recap => {
        return recap?.externalSegmentActivations
    }).flat()

    const segmentProviderListWithDuplicate = externalSegmentActivations?.reduce((acc: string[], externalSegment) => {
        const code = externalSegment?.providerCode
        if (code) {
            const providerName = providerList?.find((provider: {
                label: string,
                value: string
            }) => provider.value === code)?.label
            if (!providerName) return acc
            acc.push(providerName)
        }
        return acc
    }, [])

    return [...new Set(segmentProviderListWithDuplicate)]
}

/*
Extract the provider list from the audienceFeatures for a segment audience
@param audience
 */
export function getProvidersForSegmentAudienceFromAudienceFeatures(audience: IAudiences, providerList: Array<{
    value: string,
    label: string
}>) {
    const segmentsList = audience?.audienceFeatures?.map(feature => {
        if (!feature?.featureValues) return []

        return feature?.featureValues.map(featureValue => {
            return JSON.parse(featureValue)
        })
    }).flat()

    const segmentProviderListWithDuplicate = segmentsList?.reduce((acc: string[], externalSegment) => {
        const code = externalSegment?.providerCode
        if (code) {
            const providerName = providerList?.find((provider: {
                label: string,
                value: string
            }) => provider.value === code)?.label
            if (!providerName) return acc
            acc.push(providerName)
        }
        return acc
    }, [])

    return [...new Set(segmentProviderListWithDuplicate)]
}


export function extractIdsListForSegmentsFromActivationRecap(segments: IExternalSegmentActivation[] | undefined) {
    if (!segments) return []
    const idKeys: IdCodeType[] = [
        'numAndroidids',
        'numHems',
        'numId5s',
        'numIosids',
        'numIps',
        'numPanoids',
        'numPubmaticCookies',
        'numRampids',
        'numTtdids',
        'numUid20s'
    ];

    return idKeys.map(key => ({
        idName: key,
        total: segments.reduce((sum, segment) =>
            sum + (segment[key] || 0), 0
        )
    })).filter((idKey) => idKey.total > 0);
}