import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import React, {useEffect} from "react";
import useDebounce from "../../../components/hooks/useDebounce";
import {useSegmentBuilderStore} from "../SegmentBuilderStore";
import {DEFAULT_DISTANCE} from "../Segments.utils";

export const SegmentSearchBar = () => {

    const query = useSegmentBuilderStore(st => st.query)
    const [search, setSearch] = React.useState<string>(query ?? '');
    const debouncedSearch = useDebounce(search, 800);

    useEffect(() => {
        if (debouncedSearch) {
            useSegmentBuilderStore.setState({
                query: debouncedSearch,
                distance: DEFAULT_DISTANCE,
                searchDone: true,
                page: 0
            })
        } else if (!debouncedSearch.length) {
            useSegmentBuilderStore.setState({query: '', distance: DEFAULT_DISTANCE, page: 0})
        }
    }, [debouncedSearch])


    return <TextField id="outlined-basic" variant="outlined" placeholder="Your search"
                      onChange={(search) => setSearch(search?.target?.value)} value={search}
                      sx={{
                          width: "100%",
                          marginBottom: "1rem",
                      }}
                      inputProps={{style: {height: '30px', marginInline: '0.5rem'}}}
                      InputProps={{
                          endAdornment: search.length ? <CancelIcon onClick={() => setSearch('')} fontSize="small" sx={{
                              marginRight: '0.5rem',
                              cursor: 'pointer',
                              color: '#7587A3'
                          }}/> : <></>,
                      }}
    />
}